import { Moment } from 'moment';

export const enum AppointmentStatus {
        PENDING = 'PENDING',
        COMPLETED = 'COMPLETED'
}

export class ClaimHistoryModel {
        referenceNo: string;
        appointment: Moment;
        claimantId: string;
        agentId: string;
        agentName: string;
        endCall: Moment;
        latitude: string;
        longitude: string;
        deviceInfo: string;
        callRating: number;
        status: string;
        btnTrigger: boolean;
        deviceId: string;
}