import { OnInit } from '@angular/core';
import { CommonModalService } from './common-modal-service';
declare var window: any;

export class CommonModalFunctions implements OnInit {

  popupIsShown: string;
  popupTitle: string;
  popupContents: any;
  errorTitle: string;
  errorContents: any;
  primaryButton: string;
  secondaryButton: string;

  constructor(
    private commonModalService: CommonModalService
  ) { }

  ngOnInit() {
    this.popupIsShown = undefined;

    this.commonModalService.showErrorPopup.subscribe((response) => {
      if (response) {
        this.resetValues();

        this.errorTitle = response.title ? response.title : '';
        let errorDesc = undefined;

        if (response.error || response.message) {
          errorDesc = response.error ? response.error : '';
          errorDesc = response.error_description ? errorDesc + response.message : errorDesc;
        }

        this.errorContents = response.notShowDesc ? '' : errorDesc ? errorDesc : response.type;

        if (this.popupIsShown === 'confirm') {
          this.hidePopup();
        }
        this.popupIsShown = 'error';
        this.showPopup();
      }
    });

    this.commonModalService.showConfirmPopup.subscribe((response) => {
      if (response) {
        this.resetValues();

        if (response.content) {
          let content = response.content;
          this.popupTitle = content.title ? content.title : '';
          this.popupContents = content.body ? content.body : content;
        } else {
          this.popupContents = response;
        }

        if (response.buttons) {
          let buttons = response.buttons;
          this.primaryButton = buttons.primary;
          this.secondaryButton = buttons.secondary;
        } else {
          this.primaryButton = 'OK';
          this.secondaryButton = 'Cancel';
        }

        if (response.callback) {
          this.commonModalService.setCallback(response.callback);
        }

        if (this.popupIsShown === 'error') {
          this.hidePopup();
        }
        this.popupIsShown = 'confirm';
        this.showPopup();
      }
    });

    this.commonModalService.showLoader.subscribe((response) => {
      this.openLoader(response);
    });

    this.commonModalService.activateQrScanner.subscribe((callback: any) => {
      var scb, fcb;
      if(callback) {
        if (callback instanceof Function) {
          scb = (text) => { callback(text) };
        } else {
          scb = (text) => { callback.scb(text) };
          fcb = callback.fcb ? () => { callback.fcb() } : undefined;
        }
      }
      this.activateQrScanner(scb, fcb);
    });

    this.commonModalService.deactivateQrScanner.subscribe(()=> {
      this.stopScanner();
    })
  }

  openLoader(isShow: boolean) {
    let loader: HTMLElement = document.querySelector('.gooey');

    if (isShow) {
      loader.classList.add('active');
    } else if (!isShow) {
      loader.classList.remove('active');
    }
  }

  showPopup(): void {
    document.documentElement.classList.add('show-popup-' + this.popupIsShown);
  }

  hidePopup(): void {
    document.documentElement.classList.remove('show-popup-' + this.popupIsShown);

    if (this.popupIsShown === 'confirm') {
      /* Delay by 0.5s for animation to complete */
      setTimeout(() => {
        this.popupTitle = undefined;
        this.popupContents = undefined;
        this.primaryButton = undefined;
        this.secondaryButton = undefined;
      }, 500);
    } else if (this.popupIsShown === 'error') {
      /* Delay by 0.5s for animation to complete */
      setTimeout(() => {
        this.errorTitle = undefined;
        this.errorContents = undefined;
      }, 500);
    }
    this.popupIsShown = undefined;
  }

  resetValues(): void {
    this.popupTitle = undefined;
    this.popupContents = undefined;
    this.primaryButton = undefined;
    this.secondaryButton = undefined;
    this.errorTitle = undefined;
    this.errorContents = undefined;
  }

  hidePopupAndCallback(button: number): void {
    this.commonModalService.getCallback(button);
    this.hidePopup();
    this.commonModalService.setCallback(undefined);
  }

  /**
   * 
   * Example passing success callback and failed callback **
   * this.commonModalService.activateQrScanner.emit({
        scb: (scannedValue) => {
          console.log("SUCCESS CALLBACK : " + scannedValue);
        }, fcb: () => {
          console.log("FAILED CALLBACK");
        }
      });
   * 
   */
  activateQrScanner(scb?: Function, fcb?: Function) {
    window.QRScanner.prepare((err, status) => {
      if (err) {
        console.error(err);
        if (err.code == 1) {
          this.commonModalService.showErrorPopup.emit("Camera permission has been denied. Please change permission at your phone settings.");
        } else {
          this.commonModalService.showErrorPopup.emit("An error has occured.");
        }
        if (fcb) {
          fcb();
        }
        return;
      }
      console.log('QRScanner is initialized. Status:');
      console.log(status);
      if (status && status.authorized) {
        this.startScanner(scb, fcb);
      } else if (status && status.denied) {
        // The video preview will remain black, and scanning is disabled. We can
        // try to ask the user to change their mind, but we'll have to send them
        // to their device settings with `QRScanner.openSettings()`.
        this.commonModalService.showErrorPopup.emit("Camera permission has been denied. Please change permission at your phone settings.");
        if (fcb) {
          fcb();
        }
        return;
      } else {
        // we didn't get permission, but we didn't get permanently denied. (On
        // Android, a denial isn't permanent unless the user checks the "Don't
        // ask again" box.) We can ask again at the next relevant opportunity.
        if (fcb) {
          fcb();
        }
        return;
      }
    });
  }

  startScanner(scb: Function, fcb: Function) {
    console.log("startScanner");
    let self = this;
    //$.mobile.changePage('pages/auth/auth_qrScan_startScan.html');
    window.QRScanner.scan((err, text) => {
      console.log(text);
      if (err) {
        console.log(err);
        if (fcb) {
          fcb();
        }
      } else {
        scb(text);
      }
      self.stopScanner();
    });
    window.QRScanner.show(() => {
      console.log("Camera view on");
      self.commonModalService.showCamera.emit(true);
    });
  }

  stopScanner() {
    console.log("stopScanner");
    let self = this;
    window.QRScanner.destroy(() => {
      console.log("Camera view off");
      self.commonModalService.showCamera.emit(false);
    });
  }

}
