declare var require: any;
declare var google: any;
declare var navigator: any;

import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as $ from 'jquery';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { ChatHistoryModel } from 'src/app/model/history/chat-history-model';
import { AppointmentStatus, ClaimHistoryModel } from 'src/app/model/history/claim-history-model';
import { ClaimPhotoModel } from 'src/app/model/history/claim-photo-model';
import { CountryCodeEntityModel } from 'src/app/model/history/country-code-entity-model';
import { LanguageModel } from 'src/app/model/history/language-model';
import { UserProfileModel } from 'src/app/model/profile/user-profile-model';
import { SessionService } from 'src/app/service/common/session.service';
import { RestService } from 'src/app/service/rest/rest.service';
import { environment } from "../../../environments/environment";
import { AgentProfileModel, AgentStatus } from 'src/app/model/profile/agent-profile-model';
import { AppConstant } from 'src/app/constant/app-constant';
import { RestUrl } from 'src/app/service/constant/rest-url';
import * as moment from 'moment';

const Easyrtc = require("../../../js/easyrtc");
const EasyrtcRecorder = require("../../../js/easyrtc_recorder");
const io = require('socket.io-client');
const ORIENTATION_CHANGE = "orientation_change";
const CHANGE_CAMERA = "change_camera";
const GET_CAMERA = "get_camera";
const SEND_PHOTO = "send_photo";
const GET_ALL_PHOTO = "get_all_photo";
const RETURN_PHOTO = "return_photo";
const TAKE_PHOTO = "take_photo";
const SEND_BATCH_PHOTO = "send_batch_photo";
const SCREEN_SHARE = "screen_share";
const STOP_SCREEN_SHARE = "stop_screen_share";
const OPEN_FLASH = "open_flash";
const ZOOM_CAMERA = "zoom_camera";
const SEND_DEVICE_INFO = "send_device_info";
const HANGUP = "hangup";
const GET_LOCATION = "get_location";
const AGENT_CHAT = "agent_chat";
const CLAIMANT_CHAT = "claimant_chat";
const LOW_BANDWIDTH = "low_bandwidth";
const LANGUAGE = "language";
const CALLER_RECORDING = "caller_recording";
const PEER_USER = "peer_user";
const RESPONSE_BODY = "response_body";
const COUNTRY_CODE = "country_code";
const IMAGEFORMAT = "image/jpeg";
const PORTRAIT_IMAGEBOX_SIZE = 490;
const LANDSCAPE_IMAGEBOX_SIZE = 870;
const PREVIEW_IMAGE_SIZE = 150;
const MAX_CHAT_STORED = 5;

@Component({
  selector: 'vidsight-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgentComponent implements OnInit {

  easyrtc = new Easyrtc();
  easyrtcRecorder = new EasyrtcRecorder();

  //html
  selfVideo: any;
  callerVideo: any;
  shareVideo: any;
  iam: any;
  topIcon: any;
  currentTime: any;
  openChatButton: any;
  chatSection: any;
  unreadChatIdentifier: any;
  unreadChatCountSpan: any;
  chatBoxBody: any;
  chatBoxSendButton: any;
  chatBoxInput: any;
  bottomButton: any;
  hangupButton: any;
  pauseVideoButton: any;
  mutedVoiceButton: any;
  voiceButton: any;
  mutedVideoButton: any;
  videoButton: any;
  changeCameraButton: any;
  flashButton: any;
  mutedFlashButton: any;
  gpsTrackerButton: any;
  takePhotoButton: any;
  zoomSlider: any;
  previewDiv: any;
  previewFuncDiv: any;
  previewWrapFuncDiv: any;
  screenSharingButton: any;
  sendImagesButton: any;
  leftNavigationButton: any;
  rightNavigationButton: any;
  imageBox: any;
  imageBoxBehind: any;
  previousButton: any;
  nextButton: any;
  closeButton: any;
  undoButton: any;
  saveButton: any;
  palette: any;
  colourButton: any;
  viewAllDiv: any;
  viewAllDivButton: any;
  pagingText: any;
  mapBox: any;
  mapBoxBehind: any;
  mapFuncDiv: any;
  closeMapButton: any;
  videoSrcList: any;
  selectVideoSrcBox: any;
  webCamBlockedBox: any;
  cameraBoxBehind: any;

  //var
  responseBody: ClaimHistoryModel;
  peerUserProfile: UserProfileModel;
  agentProfile: AgentProfileModel;
  countryCodeBody: CountryCodeEntityModel[];
  localeLabelBody: LanguageModel[];
  localeLabelBodyMap = new Map();
  occupantsList: any;

  SKIP_CHECKING_LANGUAGE: boolean = true;
  windowStream: any;
  screenStream: any;
  imageArray: any = [];
  originalImageArray: any = [];
  imageOrientationArray: any = [];
  timerCounter: any;

  selfMediaRecorder: any;
  callerMediaRecorder: any;
  selfRecordStream: any;
  callerRecordStream: any;
  colorList = ["#000000", "#0000FF", "#FF0000", "#008000", "#FFFF00"];

  referenceNo: string;
  appHash: string;
  roomHash: string;
  peerEasyrtcid: string;
  idToken: string;
  selfEasyrtcid: string;
  selfName: string;
  videoSrcId: string;
  latitude: string;
  longitude: string;
  osVersion: string;
  browserVersion: string;
  drawingImage: string;
  drawingCanvas: HTMLCanvasElement;
  drawingCanvasId: string;
  curColor: string = "#000000";
  conversationAgent: string = "@Agent";
  conversationClaimant: string = "@Claimant";
  conversationText: string = "Say something";
  countryCode: string;

  play: boolean = true;
  voiceMuted: boolean = false;
  videoMuted: boolean = false;
  flashMuted: boolean = true;
  isShiftKeyPressed: boolean = false;
  startScreenShare: boolean = false;
  selfRoomOwner: boolean = false;
  isBackCamera: boolean = false;
  viewAll: boolean = false;
  isSafari: boolean = false;
  isDrawing: boolean = false;
  isChatBoxOpen: boolean = false;
  onlyOffOnce: boolean = false;
  selfRecording: boolean = false;
  callerRecording: boolean = false;

  totalSeconds: number = 0;
  countryCodeCounter: number = 0;
  previewScrollIndex: number = 0;
  unreadChatCount: number = 0;
  chatCount: number = 0;

  clickX = new Array();
  clickY = new Array();
  clickDrag = new Array();
  clickColor = new Array();
  paint: any;
  undoClickX = new Array();
  undoClickY = new Array();
  undoClickDrag = new Array();
  undoClickColor = new Array();

  constructor(
    private restService: RestService,
    private sessionService: SessionService) { }

  ngOnInit() {
    this.roomHash = this.sessionService.agentRoomNumber;
    console.log("this.sessionService.agentRoomNumber:", this.sessionService.agentRoomNumber);
    this.easyrtc.setIo(io);
    this.easyrtc.setSocketUrl(`${environment.socketUrl}:${environment.socketPort}`, null);
  }

  ngAfterViewInit() {
    this.getElement();
    if (!this.roomHash) {
      this.roomHash = Math.floor(Math.random() * 0xFFFFFF).toString(16);
    }
    this.appHash = "easyrtc.audioVideo";
    this.easyrtc.initMediaSource(() => { },
      (errorCode: any, errorText: any) => {
        console.log(errorCode + " " + errorText);
        this.webCamBlockedBox.style.display = "block";
        this.cameraBoxBehind.style.display = "block";
      });

    $(window).resize(this.updateChatScroll);
    this.easyrtc.setAcceptChecker(this.setAcceptChecker.bind(this));
    this.createElement();
    this.connect();
  }

  private setAcceptChecker(easyrtcid: any, callback: any): void {
    if (this.easyrtc.getConnectionCount() > 0) {
      console.log("drop current call and accept new call");
    } else {
      console.log("accept incoming new call");
    }
  }

  getElement() {
    this.selfVideo = document.getElementById("selfVideo") as HTMLVideoElement;
    this.callerVideo = document.getElementById("callerVideo") as HTMLVideoElement;
    this.shareVideo = document.getElementById('shareVideo') as HTMLVideoElement;
    this.iam = document.getElementById("iam");
    this.topIcon = document.getElementById("topIcon");
    this.currentTime = document.getElementById("currentTime");
    this.openChatButton = document.getElementById("openChatButton");
    this.chatSection = document.getElementById("chatSection");
    this.unreadChatIdentifier = document.getElementById("unread-chat");
    this.unreadChatCountSpan = document.getElementById("unread-chat-count");
    this.chatBoxBody = document.getElementById("chatBoxBody");
    this.chatBoxSendButton = document.getElementById("chatBoxSend");
    this.chatBoxInput = document.getElementById("chatBoxInput") as HTMLTextAreaElement;
    this.bottomButton = document.getElementById("bottomButton");
    this.changeCameraButton = document.getElementById("changeCameraButton") as HTMLButtonElement;
    this.flashButton = document.getElementById("flashButton") as HTMLButtonElement;
    this.mutedFlashButton = document.getElementById("mutedFlashButton") as HTMLButtonElement;
    this.gpsTrackerButton = document.getElementById("gpsTrackerButton") as HTMLButtonElement;
    this.takePhotoButton = document.getElementById("takePhotoButton") as HTMLButtonElement;
    this.zoomSlider = document.getElementById("zoomSlider") as HTMLInputElement;
    this.previewDiv = document.getElementById('preview');
    this.imageBox = document.getElementById('imageBox');
    this.imageBoxBehind = document.getElementById('imageBoxBehind');
    this.previousButton = document.getElementById('previousButton');
    this.nextButton = document.getElementById('nextButton');
    this.palette = document.getElementById("palette")
    this.viewAllDiv = document.getElementById("viewAllDiv");
    this.viewAllDivButton = document.getElementById("viewAllDivButton");
    this.mapBox = document.getElementById("mapBox");
    this.mapBoxBehind = document.getElementById("mapBoxBehind");
    this.mapFuncDiv = document.getElementById("mapFuncDiv");
    this.videoSrcList = document.getElementById("videoSrcList");
    this.selectVideoSrcBox = document.getElementById("selectVideoSrcBox");
    this.webCamBlockedBox = document.getElementById("webCamBlockedBox");
    this.cameraBoxBehind = document.getElementById("cameraBoxBehind");
  }

  createElement() {
    this.hangupButton = document.createElement("button");
    this.hangupButton.id = "hangupButton";
    this.hangupButton.title = "收線";
    this.hangupButton.className = "bottomButtons buttonImage";
    this.hangupButton.onclick = () => {
      this.hangup();
    }

    this.pauseVideoButton = document.createElement("button");
    this.play = true;
    this.pauseVideoButton.id = "pauseVideoButton";
    this.pauseVideoButton.title = "暂停";
    this.pauseVideoButton.className = "bottomButtons buttonImage pauseVideoButton";
    this.pauseVideoButton.onclick = () => {
      this.pauseVideoButtonOnClick();
    }

    this.mutedVoiceButton = document.createElement("button");
    this.mutedVoiceButton.id = "mutedVoiceButton";
    this.mutedVoiceButton.className = "mutedBottomButton";
    this.mutedVoiceButton.title = "開啓靜音";
    this.voiceButton = document.createElement("button");
    this.voiceButton.id = "voiceButton";
    this.voiceButton.title = "開啓靜音";
    this.voiceButton.className = "bottomButtons buttonImage voiceButton";
    this.voiceButton.onclick = () => {
      this.voiceFuncButton();
    }
    this.mutedVoiceButton.onclick = () => {
      this.voiceFuncButton();
    }

    this.mutedVideoButton = document.createElement("button");
    this.mutedVideoButton.id = "mutedVideoButton";
    this.mutedVideoButton.className = "mutedBottomButton";
    this.mutedVideoButton.title = "閂錄影機";
    this.videoButton = document.createElement("button");
    this.videoButton.id = "videoButton";
    this.videoButton.title = "閂錄影機";
    this.videoButton.className = "bottomButtons buttonImage videoButton";
    this.videoButton.onclick = () => {
      this.videoFuncButton();
    }
    this.mutedVideoButton.onclick = () => {
      this.videoFuncButton();
    }

    this.changeCameraButton.onclick = () => {
      this.sendChangeCameraEvent();
    }

    this.flashButton.onclick = () => {
      if (this.flashMuted) {
        this.flashMuted = false;
        this.mutedFlashButton.style.display = "none";
        this.easyrtc.sendDataWS(this.peerEasyrtcid, OPEN_FLASH, !this.flashMuted);
      } else {
        this.flashMuted = true;
        this.mutedFlashButton.style.display = "block";
        this.easyrtc.sendDataWS(this.peerEasyrtcid, OPEN_FLASH, !this.flashMuted);
      }
    }

    this.mutedFlashButton.onclick = () => {
      if (this.flashMuted) {
        this.flashMuted = false;
        this.mutedFlashButton.style.display = "none";
        this.easyrtc.sendDataWS(this.peerEasyrtcid, OPEN_FLASH, !this.flashMuted);
      } else {
        this.flashMuted = true;
        this.mutedFlashButton.style.display = "block";
        this.easyrtc.sendDataWS(this.peerEasyrtcid, OPEN_FLASH, !this.flashMuted);
      }
    }

    this.gpsTrackerButton.onclick = () => {
      this.easyrtc.sendDataWS(this.peerEasyrtcid, GET_LOCATION, null);
    }

    this.takePhotoButton.onclick = () => {
      this.sendTakePhotoEvent();
    }

    this.chatBoxSendButton.onclick = () => {
      this.sendChat();
    }

    this.chatBoxInput.addEventListener("keydown", e => {
      if (e.keyCode == 16) {
        this.isShiftKeyPressed = true;
      }
    });
    this.chatBoxInput.addEventListener("keyup", e => {
      if (e.keyCode == 16) {
        this.isShiftKeyPressed = false;
      }
    });
    $(this.chatBoxInput).keypress(e => {
      if (!this.isShiftKeyPressed && e.keyCode == 13) {
        e.preventDefault();
        this.sendChat();
      }
    });

    this.bottomButton.appendChild(this.pauseVideoButton);
    this.bottomButton.appendChild(this.hangupButton);
    this.bottomButton.appendChild(this.mutedVoiceButton);
    this.bottomButton.appendChild(this.voiceButton);
    this.bottomButton.appendChild(this.mutedVideoButton);
    this.bottomButton.appendChild(this.videoButton);

    this.previewFuncDiv = document.createElement('div');
    this.previewFuncDiv.id = "previewFuncDiv";
    this.previewFuncDiv.className = "previewFuncDiv";

    this.previewWrapFuncDiv = document.createElement('div');
    this.previewWrapFuncDiv.id = "previewWrapFuncDiv";
    this.previewWrapFuncDiv.className = "previewWrapFuncDiv";

    this.screenSharingButton = document.createElement('button');
    this.screenSharingButton.id = "screenSharingButton";
    this.screenSharingButton.title = "共享熒幕";
    this.screenSharingButton.className = "buttonImage previewFunctionButton";
    this.screenSharingButton.onclick = () => {
      if (!this.startScreenShare) {
        this.screenShare();
        this.startScreenShare = true;
      } else {
        this.screenSharingButton.classList.remove("screenSharing");
        this.easyrtc.sendDataWS(this.peerEasyrtcid, STOP_SCREEN_SHARE, null);
        this.startScreenShare = false;
      }
    }

    this.sendImagesButton = document.createElement('button');
    this.sendImagesButton.id = "sendImagesButton";
    this.sendImagesButton.title = "傳送相片";
    this.sendImagesButton.className = "buttonImage previewFunctionButton";
    this.sendImagesButton.onclick = () => {
      this.easyrtc.sendDataWS(this.peerEasyrtcid, SEND_BATCH_PHOTO, this.imageArray);
    }

    this.leftNavigationButton = document.createElement('button');
    this.leftNavigationButton.id = "leftNavigationButton";
    this.leftNavigationButton.title = "上一個";
    this.leftNavigationButton.className = "buttonImage previewNavigationButton";

    this.rightNavigationButton = document.createElement('button');
    this.rightNavigationButton.id = "rightNavigationButton";
    this.rightNavigationButton.title = "下一個";
    this.rightNavigationButton.className = "buttonImage previewNavigationButton";

    //this.previewWrapFuncDiv.appendChild(screenSharingButton);
    this.previewWrapFuncDiv.appendChild(this.sendImagesButton);
    this.previewFuncDiv.appendChild(this.previewWrapFuncDiv);
    this.previewDiv.appendChild(this.previewFuncDiv);
    this.previewDiv.appendChild(this.leftNavigationButton);
    this.previewDiv.appendChild(this.rightNavigationButton);

    this.nextButton.onclick = () => {
      const canvas = document.querySelector('#' + this.drawingCanvasId) as HTMLCanvasElement;
      var image = new Image();
      image.src = this.drawingCanvas.toDataURL(IMAGEFORMAT, .7);
      image.onload = () => {
        this.drawCanvas(image, 1, PREVIEW_IMAGE_SIZE, canvas);
        var arrayId = this.drawingCanvasId.split('-')[1];
        canvas.onclick = () => {
          var newArrayId = canvas.id.split('-')[1];
          var orientation = this.imageOrientationArray[Number(newArrayId) - 1];
          this.drawingImage = this.imageArray[Number(newArrayId) - 1];
          this.drawingCanvasId = "image-".concat(newArrayId);
          this.drawImageBox(image.src, orientation);
          this.navigation();
        }
        this.imageArray[Number(arrayId) - 1] = image.src;

        this.clearImageBoxStroke();
        var newArrayId = Number(this.drawingCanvasId.split('-')[1]) + 1;
        var newImage = new Image();
        var newOrientation = this.imageOrientationArray[newArrayId - 1];
        newImage.src = this.imageArray[newArrayId - 1];
        newImage.onload = () => {
          this.drawingImage = this.imageArray[newArrayId - 1];
          this.drawingCanvasId = "image-".concat(String(newArrayId));
          this.drawImageBox(newImage.src, newOrientation);
          this.navigation();
        }
      }
    }

    this.previousButton.onclick = () => {
      const canvas = document.querySelector('#' + this.drawingCanvasId) as HTMLCanvasElement;
      var image = new Image();
      image.src = this.drawingCanvas.toDataURL(IMAGEFORMAT, .7);
      image.onload = () => {
        this.drawCanvas(image, 1, PREVIEW_IMAGE_SIZE, canvas);
        var arrayId = this.drawingCanvasId.split('-')[1];
        canvas.onclick = () => {
          var newArrayId = canvas.id.split('-')[1];
          var orientation = this.imageOrientationArray[Number(newArrayId) - 1];
          this.drawingImage = this.imageArray[Number(newArrayId) - 1];
          this.drawingCanvasId = "image-".concat(newArrayId);
          this.drawImageBox(image.src, orientation);
          this.navigation();
        }
        this.imageArray[Number(arrayId) - 1] = image.src;

        this.clearImageBoxStroke();
        var newArrayId = Number(this.drawingCanvasId.split('-')[1]) - 1;
        var newImage = new Image();
        var newOrientation = this.imageOrientationArray[newArrayId - 1];
        newImage.src = this.imageArray[newArrayId - 1];
        newImage.onload = () => {
          this.drawingImage = this.imageArray[newArrayId - 1];
          this.drawingCanvasId = "image-".concat(String(newArrayId));
          this.drawImageBox(newImage.src, newOrientation);
          this.navigation();
        }
      }
    }
    this.pagingText = document.createElement("div");
    this.pagingText.id = "pagingText";

    this.closeButton = document.createElement("button");
    this.closeButton.id = "closeButton";
    this.closeButton.title = "關閉";
    this.closeButton.className = "functionButtons buttonImage";
    this.closeButton.onclick = () => {
      this.isDrawing = false;
      this.easyrtc.sendDataWS(this.peerEasyrtcid, SCREEN_SHARE, false);
      this.imageBox.style.display = "none";
      this.imageBoxBehind.classList.remove("imageBoxBehind");
      this.clearImageBox();
    }

    this.undoButton = document.createElement("button");
    this.undoButton.id = "undoButton";
    this.undoButton.title = "重置";
    this.undoButton.className = "functionButtons buttonImage";
    this.undoButton.onclick = () => {
      var arrayIndex = Number(this.drawingCanvasId.split('-')[1]) - 1;
      var orientation = this.imageOrientationArray[arrayIndex];
      if (this.undoClickX === undefined || this.undoClickX.length == 0) {
        var imageBig = new Image();
        imageBig.src = this.originalImageArray[arrayIndex];
        this.drawingImage = this.originalImageArray[arrayIndex];
        imageBig.onload = () => {
          var imageBox = document.getElementById('imageBox');
          imageBox.style.display = "block";
          const canvas = document.querySelector('#takePhotoCanvas');
          if (orientation == 0) {
            this.drawingCanvas = this.drawCanvas(imageBig, 1, PORTRAIT_IMAGEBOX_SIZE, canvas);
          } else if (orientation == 90 || orientation == -90) {
            this.drawingCanvas = this.drawCanvas(imageBig, 1, LANDSCAPE_IMAGEBOX_SIZE, canvas);
          }
        }
      } else {
        var imageBig = new Image();
        imageBig.src = this.drawingImage;
        imageBig.onload = () => {
          var imageBox = document.getElementById('imageBox');
          imageBox.style.display = "block";
          const canvas = document.querySelector('#takePhotoCanvas');
          if (orientation == 0) {
            this.drawingCanvas = this.drawCanvas(imageBig, 1, PORTRAIT_IMAGEBOX_SIZE, canvas);
          } else if (orientation == 90 || orientation == -90) {
            this.drawingCanvas = this.drawCanvas(imageBig, 1, LANDSCAPE_IMAGEBOX_SIZE, canvas);
          }
          this.undoDraw(this.drawingCanvas.getContext('2d'));
        }
      }
    }

    this.saveButton = document.createElement("button");
    this.saveButton.id = "saveButton";
    this.saveButton.title = "儲存";
    this.saveButton.className = "functionButtons buttonImage";
    this.saveButton.onclick = () => {
      this.isDrawing = false;
      //this.easyrtc.sendDataWS(peerEasyrtcid, SCREEN_SHARE, false);
      const canvas = document.querySelector('#' + this.drawingCanvasId) as HTMLCanvasElement;
      var image = new Image();
      image.src = this.drawingCanvas.toDataURL(IMAGEFORMAT, .7);
      image.onload = () => {
        this.drawCanvas(image, 1, PREVIEW_IMAGE_SIZE, canvas);
        var arrayId = this.drawingCanvasId.split('-')[1];
        canvas.onclick = () => {
          var newArrayId = canvas.id.split('-')[1];
          this.isDrawing = true;
          //this.easyrtc.sendDataWS(peerEasyrtcid, SCREEN_SHARE, true);
          var orientation = this.imageOrientationArray[Number(newArrayId) - 1];
          this.drawingImage = this.imageArray[Number(newArrayId) - 1];
          this.drawingCanvasId = "image-".concat(newArrayId);
          this.drawImageBox(image.src, orientation);
          this.navigation();
        }
        this.imageArray[Number(arrayId) - 1] = image.src;
        this.imageBox.style.display = "none";
        this.imageBoxBehind.classList.remove("imageBoxBehind");
        this.clearImageBox();
      }
    }
    this.imageBox.appendChild(this.pagingText);
    this.imageBox.appendChild(this.closeButton);
    this.imageBox.appendChild(this.undoButton);
    this.imageBox.appendChild(this.saveButton);
  }

  pauseVideoButtonOnClick() {
    if (this.play) {
      //this.endCallerVideoRecording();
      this.play = false;
      this.pauseVideoButton.classList.remove("pauseVideoButton");
      this.pauseVideoButton.className += " playVideoButton";
      this.voiceMuted = true;
      this.easyrtc.enableMicrophone(false);
      this.mutedVoiceButton.style.display = "initial";
      if (this.windowStream) {
        this.windowStream.getTracks().forEach(track => {
          track.enabled = false;
        })
      }
      if (this.localeLabelBody) {
        this.pauseVideoButton.title = this.localeLabelBodyMap.get("playVideoButton") != null ? this.localeLabelBodyMap.get("pauseVideoButton") : this.pauseVideoButton.title;
        this.voiceButton.title = this.localeLabelBodyMap.get("switchOnVoiceButton") != null ? this.localeLabelBodyMap.get("switchOnVoiceButton") : this.voiceButton.title;
      } else {
        this.pauseVideoButton.title = "播放";
        this.voiceButton.title = "開啓靜音";
      }
    } else {
      this.play = true
      this.pauseVideoButton.classList.remove("playVideoButton");
      this.pauseVideoButton.className += " pauseVideoButton";
      this.voiceMuted = false;
      this.easyrtc.enableMicrophone(true);
      this.mutedVoiceButton.style.display = "none";
      if (this.windowStream) {
        this.windowStream.getTracks().forEach(track => {
          track.enabled = true;
        })
      }
      if (this.localeLabelBody) {
        this.pauseVideoButton.title = this.localeLabelBodyMap.get("pauseVideoButton") != null ? this.localeLabelBodyMap.get("pauseVideoButton") : this.pauseVideoButton.title;
        this.voiceButton.title = this.localeLabelBodyMap.get("switchOffVoiceButton") != null ? this.localeLabelBodyMap.get("switchOffVoiceButton") : this.voiceButton.title;
      } else {
        this.pauseVideoButton.title = "暂停";
        this.voiceButton.title = "關閉靜音";
      }
      //this.startCallerVideoRecording();
    }
  }

  voiceFuncButton() {
    if (this.voiceMuted) {
      this.voiceMuted = false;
      this.mutedVoiceButton.style.display = "none";
      this.easyrtc.enableMicrophone(true);
      if (this.localeLabelBody) {
        this.voiceButton.title = this.localeLabelBodyMap.get("switchOffVoiceButton") != null ? this.localeLabelBodyMap.get("switchOffVoiceButton") : this.voiceButton.title;
        this.mutedVoiceButton.title = this.localeLabelBodyMap.get("switchOffVoiceButton") != null ? this.localeLabelBodyMap.get("switchOffVoiceButton") : this.mutedVoiceButton.title;
      } else {
        this.voiceButton.title = "開啓靜音";
        this.mutedVoiceButton.title = "開啓靜音";
      }
    } else {
      this.voiceMuted = true
      this.mutedVoiceButton.style.display = "initial";
      this.easyrtc.enableMicrophone(false);

      if (this.localeLabelBody) {
        this.voiceButton.title = this.localeLabelBodyMap.get("switchOnVoiceButton") != null ? this.localeLabelBodyMap.get("switchOnVoiceButton") : this.voiceButton.title;
        this.mutedVoiceButton.title = this.localeLabelBodyMap.get("switchOnVoiceButton") != null ? this.localeLabelBodyMap.get("switchOnVoiceButton") : this.mutedVoiceButton.title;

      } else {
        this.voiceButton.title = "關閉靜音";
        this.mutedVoiceButton.title = "關閉靜音";
      }
    }
  }

  videoFuncButton() {
    if (this.videoMuted) {
      this.videoMuted = false;
      this.mutedVideoButton.style.display = "none";
      this.easyrtc.enableCamera(true);
      if (this.localeLabelBody) {
        this.videoButton.title = this.localeLabelBodyMap.get("switchOffVideoButton") != null ? this.localeLabelBodyMap.get("switchOffVideoButton") : this.videoButton.title;
        this.mutedVideoButton.title = this.localeLabelBodyMap.get("switchOffVideoButton") != null ? this.localeLabelBodyMap.get("switchOffVideoButton") : this.mutedVideoButton.title;
      } else {
        this.videoButton.title = "閂錄影機";
        this.mutedVideoButton.title = "閂錄影機";
      }
      //this.startSelfVideoRecording();
    } else {
      //this.endSelfVideoRecording();
      this.videoMuted = true;
      this.mutedVideoButton.style.display = "initial";
      this.easyrtc.enableCamera(false);
      if (this.localeLabelBody) {
        this.videoButton.title = this.localeLabelBodyMap.get("switchOnVideoButton") != null ? this.localeLabelBodyMap.get("switchOnVideoButton") : this.videoButton.title;
        this.mutedVideoButton.title = this.localeLabelBodyMap.get("switchOnVideoButton") != null ? this.localeLabelBodyMap.get("switchOnVideoButton") : this.mutedVideoButton.title;
      } else {
        this.videoButton.title = "開錄影機";
        this.mutedVideoButton.title = "開錄影機";
      }
    }
  }

  hangup() {
    this.easyrtc.sendDataWS(this.peerEasyrtcid, HANGUP, null);
    this.easyrtc.hangupAll();
    this.hangupButton.style.display = "none";
    this.isChatBoxOpen = false;
    this.openChatButton.classList.remove("hide");
    this.openChatButton.className += " hide";
    this.chatSection.className = "hide";
    this.clearVideoTag();
    this.uploadImageService();
  }

  clearVideoTag() {
    this.clearTimer();
    this.iam.innerHTML = "";
  }

  connect() {
    this.easyrtc.enableAudio(true);
    this.easyrtc.enableVideo(true);
    this.easyrtc.enableDataChannels(true);
    this.easyrtc.setRoomOccupantListener(this.convertListToButtons.bind(this));
    this.easyrtc.setRoomEntryListener(this.roomEntryListener.bind(this));
    this.easyrtc.setPeerListener(this.sendPeerEvent.bind(this));
    this.easyrtc.setUsername("agent");
    this.easyrtc.joinRoom(this.roomHash, null, this.successJoinRoomCB.bind(this),
      (errorCode, errorText, roomName) => {
        console.log(errorCode, errorText + ": room name was(" + roomName + ")");
      });
    this.idToken = this.getToken();
    this.easyrtc.setCredential({ agent_token: this.idToken });
    this.easyrtc.setStreamAcceptor((easyrtcid, stream) => this.setStreamAcceptor(easyrtcid, stream));
    this.easyrtc.connect(this.appHash,
      (easyrtcid) => {
        this.selfEasyrtcid = easyrtcid;
        this.selfName = this.easyrtc.idToName(easyrtcid) === easyrtcid ? "" : this.easyrtc.idToName(easyrtcid);
        this.showVideo();
      },
      (errorCode, message) => {
        console.log(errorCode, message);
        this.disconnect();
      });
  }

  b64utoutf8(a) {
    return new Buffer(this.b64utob64(a), "base64").toString("utf8")
  }

  b64utob64(a) {
    if (a.length % 4 == 2) {
      a = a + "=="
    } else {
      if (a.length % 4 == 3) {
        a = a + "="
      }
    }
    a = a.replace(/-/g, "+");
    a = a.replace(/_/g, "/");
    return a
  }

  convertListToButtons(roomName, occupants, selfInfo) {
    console.log("occupants:", occupants);
    this.occupantsList = occupants;
    for (var easyrtcid in occupants) {
      if (selfInfo.roomJoinTime > occupants[easyrtcid].roomJoinTime) {
        this.selfRoomOwner = false;
      }
    }
    var size = Object.keys(occupants).length;
    if (size === 0) {
      this.selfRoomOwner = true;
    }
  }

  roomEntryListener(entered, roomName) {
    if (entered) {
      console.log("you enter room " + roomName);
    }
  }

  successJoinRoomCB(roomName) {
    console.log("you success joined room " + roomName);
  }

  successLeaveRoomCB(roomName) {
    console.log("you success leave room " + roomName);
  }

  getToken() {
    var authToken = JSON.parse(localStorage.getItem("jhi-authenticationtoken") || sessionStorage.getItem("jhi-authenticationtoken"));
    console.log('getToken#authToken');
    console.log(sessionStorage);
    console.log(authToken);
    if (authToken) {
      return authToken;
    } else {
      return "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfVVNFUiIsImV4cCI6MTU1OTk2MjQ1MH0.nirOFyn4awIYgAH_3fAszHhTvUB3R4Nw5C39KReexm9RRjud8-rCXpmjNaiOwAsaPTW8fiSr9YDV9hKpqoeYpA";
    }
  }

  showVideo() {
    this.easyrtc.getVideoSourceList((list) => {
      if (list.length > 1) {
        for (var i = 0; i < list.length; i++) {
          var ele = list[i];
          var option = document.createElement("option");
          option.text = ele.label != "" ? ele.label : ele.deviceId;
          option.value = ele.deviceId;
          this.videoSrcList.add(option);
        }
        this.selectVideoSrcBox.style.display = "block";
        this.cameraBoxBehind.style.display = "block";
      } else {
        var ele = list[0];
        this.videoSrcId = ele.deviceId;
        this.setVideoSrc();
      }
    });
  }

  selectVideoSrc() {
    this.videoSrcId = this.videoSrcList.options[this.videoSrcList.selectedIndex].value;
    this.setVideoSrc();
    this.selectVideoSrcBox.style.display = "none";
    this.cameraBoxBehind.style.display = "none";
  }

  setVideoSrc() {
    this.easyrtc.setVideoSource(this.videoSrcId);
    this.easyrtc.initMediaSource(
      (stream) => {
        this.easyrtc.clearMediaStream(this.selfVideo);
        this.easyrtc.setVideoObjectSrc(this.selfVideo, stream);
        for (var easyrtcid in this.occupantsList) {
          this.performCall(easyrtcid);
        }
      },
      (errorCode, errorText) => {
        console.log(errorCode + " " + errorText);
      });
  }

  private performCall(otherEasyrtcid: any): void {
    this.clearTimer();
    this.easyrtc.enableCamera(true);
    this.easyrtc.enableMicrophone(true);
    this.easyrtc.hangupAll();
    this.easyrtc.call(otherEasyrtcid,
      () => { },
      () => { console.log("call failure callback"); },
      this.performCallAcceptCB.bind(this));
  }

  private performCallAcceptCB(accepted: any, easyrtcid: any): void {
    if (!accepted) {
      this.easyrtc.showError("CALL-REJECTED", `Sorry, your call to ${this.easyrtc.idToName(this.peerEasyrtcid)} was rejected`);
    }
  }

  disconnect() {
    this.easyrtc.leaveRoom(this.roomHash, (roomName) => this.successLeaveRoomCB(roomName));
    this.easyrtc.disconnect();
    this.iam.innerHTML = "logged out";
    this.easyrtc.clearMediaStream(this.selfVideo);
    this.easyrtc.setVideoObjectSrc(this.selfVideo, "");
    this.easyrtc.closeLocalMediaStream();
    this.easyrtc.setRoomOccupantListener(() => { });
  }

  setStreamAcceptor(easyrtcid, stream) {
    if (!this.timerCounter) {
      this.timerCounter = setInterval(() => this.triggerTimer(), 1000);
    }
    this.windowStream = stream;
    this.setUpMirror();
    this.easyrtc.setVideoObjectSrc(this.callerVideo, stream);
    this.peerEasyrtcid = easyrtcid;
    this.bottomButton.style.display = "block";
    this.callerVideo.classList.remove("callerVideo");
    this.callerVideo.className += " selfVideo";

    this.selfRecordStream = this.easyrtc.getLocalStream();
    this.callerRecordStream = stream;
    // this.easyrtcRecorder.setRecordingVideoBitRate(800000);
    // this.startCallerVideoRecording();

    if (!this.onlyOffOnce) {
      this.videoMuted = true;
      this.videoButton.title = "開錄影機";
      this.mutedVideoButton.style.display = "initial";
      this.easyrtc.enableCamera(false);
      this.onlyOffOnce = true;
    }

    if (!this.videoMuted) {
      //this.startSelfVideoRecording();
    }

    if(!this.videoMuted){
	this.startSelfVideoRecording();
	}


    this.retrieveAndSetupChatSession();
    this.openChatButton.classList.remove("hide");
    this.isChatBoxOpen = false;
    this.unreadChatIdentifier.className = "hide";
    this.chatSection.className = "hide";
  }

  triggerTimer() {
    var hour = Math.floor(this.totalSeconds / 3600);
    var min = Math.floor((this.totalSeconds - hour * 3600) / 60);
    var sec = this.totalSeconds - (hour * 3600 + min * 60);
    this.currentTime.innerHTML = `${hour}:${this.formatTo2Digits(min)}:${this.formatTo2Digits(sec)}`;
    ++this.totalSeconds;
  }

  clearTimer() {
    clearInterval(this.timerCounter);
    this.timerCounter = undefined;
    this.totalSeconds = 0;
    this.currentTime.innerHTML = "";
  }

  formatTo2Digits(d) {
    return d < 10 ? "0".concat(d) : d;
  }

  setUpMirror() {
    this.easyrtc.sendServerMessage('Success initiate call from agent', { isBackCamera: this.isBackCamera });
    var newStream = this.easyrtc.getLocalStream(CHANGE_CAMERA);
    if (!newStream) {
      newStream = this.easyrtc.getLocalStream();
    }
    this.easyrtc.setVideoObjectSrc(this.selfVideo, newStream);
    this.selfVideo.muted = true;
    this.selfVideo.style.display = "block";
    this.selfVideo.classList.remove("selfVideo");
    this.selfVideo.className += " selfVideo-mini";
    this.mirrorCamera(this.isBackCamera);
  }

  mirrorCamera(backCamera) {
    this.selfVideo.classList.remove("easyrtcMirror");
    this.selfVideo.className += " easyrtcMirror";
    this.callerVideo.classList.remove("easyrtcMirror");
    if (!backCamera) {
      this.callerVideo.className += " easyrtcMirror";
      this.flashButton.style.color = "grey";
      this.flashButton.disabled = true;
      this.mutedFlashButton.style.backgroundColor = "grey";
      this.mutedFlashButton.disabled = true;
    } else {
      this.flashButton.style.color = "white";
      this.flashButton.disabled = false;
      this.mutedFlashButton.style.backgroundColor = "white";
      this.mutedFlashButton.disabled = false;
    }
  }

  sendPeerEvent(who, msgType, content) {
    console.log("peerEvent received: ", msgType);
    switch (msgType) {
      case RESPONSE_BODY:
        this.responseBody = content;
        this.referenceNo = this.responseBody.referenceNo;
        this.restService.getAgentProfile(+this.responseBody.agentId).subscribe((data: any) => {
          this.agentProfile = data;
          this.agentProfile.status = AgentStatus.BUSY;
          this.agentProfile.dailyCallCount = this.agentProfile.dailyCallCount ? ++this.agentProfile.totalCallCount : 1;
          this.agentProfile.totalCallCount = this.agentProfile.totalCallCount ? ++this.agentProfile.totalCallCount : 1;
          this.agentProfile.lastCallOn = moment();
          this.restService.updateAgentProfile(this.agentProfile).subscribe((busyAgent: any) => {
            this.agentProfile = busyAgent;
          });
          console.log("here:", this.responseBody);
          this.getCountryCode();
        });
        break;
      case ORIENTATION_CHANGE:
        if (content == 0) {
          this.callerVideo.style.objectFit = "contain";
        } else if (content == 90 || content == -90) {
          this.callerVideo.style.objectFit = "fill";
        }
        break;
      case PEER_USER:
        this.peerUserProfile = content;
        this.iam.innerHTML = (this.peerUserProfile.name ? this.peerUserProfile.name : this.peerUserProfile.mobileNo) + "<br />#" + this.roomHash + "<br /> OS: " + this.osVersion + "<br /> Browser: " + this.browserVersion;
        break;
      case GET_CAMERA:
        this.isBackCamera = "back" === content ? true : false;
        this.mirrorCamera(this.isBackCamera);
        break;
      case SEND_PHOTO:
        this.sendPhoto(content);
        break;
      case GET_ALL_PHOTO:
        this.easyrtc.sendDataWS(this.peerEasyrtcid, RETURN_PHOTO, this.imageArray);
        break;
      case ZOOM_CAMERA:
        var settings = content.split("|");
        this.zoomSlider.min = settings[0];
        this.zoomSlider.max = settings[1];
        this.zoomSlider.step = settings[2];
        this.zoomSlider.value = settings[3];
        this.zoomSlider.onchange = () => {
          this.easyrtc.sendDataWS(this.peerEasyrtcid, ZOOM_CAMERA, this.zoomSlider.value);
        }
        break;
      case SEND_DEVICE_INFO:
        var deviceInfo = content.split('|');
        this.browserVersion = deviceInfo[0];
        this.osVersion = deviceInfo[1];
        if (this.browserVersion.indexOf('Safari') != -1) {
          this.isSafari = true;
          this.zoomSlider.style.cursor = "default";
          this.zoomSlider.disabled = true;
          this.changeCameraButton.style.color = "grey";
          this.changeCameraButton.style.cursor = "default";
          this.changeCameraButton.disabled = true;
          this.flashButton.style.color = "grey";
          this.flashButton.style.cursor = "default";
          this.flashButton.disabled = true;
          this.mutedFlashButton.style.backgroundColor = "grey";
          this.mutedFlashButton.style.cursor = "default";
          this.mutedFlashButton.disabled = true;
          if (this.imageArray.length != 0) {
            // this.screenSharingButton.style.color = "lightgrey";
            // this.screenSharingButton.style.backgroundColor = "darkgrey";
            // this.screenSharingButton.style.cursor = "default";
            // this.screenSharingButton.disabled = true;
            // this.screenSharingButton.title = "iOS系統不支持共享熒幕";
          }
          if (this.localeLabelBody) {
            this.changeCameraButton.title = this.localeLabelBodyMap.get("iosNotSupportChangeCamera") != null ? this.localeLabelBodyMap.get("iosNotSupportChangeCamera") : this.changeCameraButton.title;
            this.flashButton.title = this.localeLabelBodyMap.get("iosNotSupportFlashButton") != null ? this.localeLabelBodyMap.get("iosNotSupportChangeCamera") : this.flashButton.title;
            this.mutedFlashButton.title = this.localeLabelBodyMap.get("iosNotSupportFlashButton") != null ? this.localeLabelBodyMap.get("iosNotSupportChangeCamera") : this.mutedFlashButton.title;
            this.zoomSlider.title = this.localeLabelBodyMap.get("iosNotSupportZoomSlider") != null ? this.localeLabelBodyMap.get("iosNotSupportChangeCamera") : this.zoomSlider.title;
          } else {
            this.changeCameraButton.title = "iOS系統不支持轉換鏡頭";
            this.flashButton.title = "iOS系統不支持電筒/開燈/閃光燈";
            this.mutedFlashButton.title = "iOS系統不支持電筒/開燈/閃光燈";
            this.zoomSlider.title = "iOS系統不支持放大/放細鏡頭";
          }
        }
        break;
      case HANGUP:
        this.easyrtc.hangupAll();
        this.hangupButton.style.display = "none";
        this.isChatBoxOpen = false;
        this.openChatButton.classList.remove("hide");
        this.openChatButton.className += " hide";
        this.chatSection.className = "hide";
        this.clearVideoTag();
        this.uploadImageService();
        break;
      case GET_LOCATION:
        var location = content.split('|');
        this.latitude = location[0];
        this.longitude = location[1];
        var locationBox = document.getElementById("locationBox");
        var locationBoxText = document.getElementById("locationBoxText");
        locationBox.style.display = "block";
        locationBoxText.style.display = "block";
        this.generateMap(this.latitude, this.longitude);
        break;
      case CLAIMANT_CHAT:
        this.receiveChatEvent(content);
        break;
      case LOW_BANDWIDTH:
        if (content) {
          this.topIcon.style.display = "block";
        } else {
          this.topIcon.style.display = "none";
        }
        break;
      case CALLER_RECORDING:
        if (content) {
          //this.startCallerVideoRecording();
        } else {
          //this.endCallerVideoRecording();
        }
        break;
      case COUNTRY_CODE:
        this.countryCode = content;
        console.log(" this.countryCode:", this.countryCode);
    }
  }

  /* Take Photo */
  sendTakePhotoEvent() {
    this.easyrtc.sendDataWS(this.peerEasyrtcid, TAKE_PHOTO, null);
  }

  /* Change Camera */
  sendChangeCameraEvent() {
    if (this.isBackCamera) {
      this.easyrtc.sendDataWS(this.peerEasyrtcid, CHANGE_CAMERA, "front");
    } else {
      this.easyrtc.sendDataWS(this.peerEasyrtcid, CHANGE_CAMERA, "back");
    }
    console.log("sendChangeCamera");
    setTimeout(() => {
      console.log("checkVideoSize");
      this.checkVideoSize();
    }, 3000);
  }

  checkVideoSize() {
    var active = false;
    if (this.callerVideo.srcObject != null) {
      active = this.callerVideo.srcObject.active;
    }
    var callerSrc = {
      videoHeight: this.callerVideo.videoHeight,
      videoWidth: this.callerVideo.videoWidth,
      callerSrcObject: this.callerVideo.srcObject,
      callerSrcObjectActive: active
    };
    console.log("Check Video Size", callerSrc);
    if ((this.callerVideo.videoHeight === 0 && this.callerVideo.videoWidth === 0) || !active) {
      for (var easyrtcid in this.occupantsList) {
        this.performCall(easyrtcid);
      }
    }
  }

  /* Send Photo */
  sendPhoto(content) {
    if (this.isDrawing && this.drawingCanvasId) {
      var arrayId = Number(this.drawingCanvasId.split('-')[1]) + 1;
      this.drawingCanvasId = "image-".concat(String(arrayId));
      this.pagingText.innerText = arrayId + " of " + this.imageArray.length;
    }
    var imageUrl = content.split('|')[0];
    var orientation = content.split('|')[1];
    for (var i = this.imageArray.length; i > 0; i--) {
      var childSubDiv = document.getElementById("previewSubDiv-".concat(i));
      var childImage = document.getElementById("image-".concat(i));
      var childRemoveButton = document.getElementById("removeImageButton-".concat(i));
      childSubDiv.id = "previewSubDiv-".concat(i + 1);
      childImage.id = "image-".concat(i + 1);
      childRemoveButton.id = "removeImageButton-".concat(i + 1);
    }
    if (this.imageArray.length != 0) {
      this.imageArray.splice(0, 0, imageUrl);
      this.originalImageArray.splice(0, 0, imageUrl);
      this.imageOrientationArray.splice(0, 0, orientation);
    } else {
      this.imageArray.push(imageUrl);
      this.originalImageArray.push(imageUrl);
      this.imageOrientationArray.push(orientation);
    }
    var image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      var previewSubDiv = document.createElement('div');
      previewSubDiv.id = "previewSubDiv-1";
      previewSubDiv.className = "previewSubDiv";
      var removeImageButton = document.createElement('button');
      removeImageButton.id = "removeImageButton-1";
      if (this.localeLabelBody) {
        removeImageButton.title = this.localeLabelBodyMap.get("removeImageButton") != null ? this.localeLabelBodyMap.get("removeImageButton") : removeImageButton.title;
      } else {
        removeImageButton.title = "撤銷";
      }
      removeImageButton.className = "removeImageButton buttonImage";
      removeImageButton.onclick = () => {
        var childDiv = document.getElementById(previewSubDiv.id);
        this.previewDiv.removeChild(childDiv);
        var nextArrayId = Number(previewSubDiv.id.split('-')[1]) + 1;
        if (nextArrayId <= this.imageArray.length) {
          for (var i = nextArrayId; i <= this.imageArray.length; i++) {
            var childSubDiv = document.getElementById("previewSubDiv-".concat(String(i)));
            var childImage = document.getElementById("image-".concat(String(i)));
            var childRemoveButton = document.getElementById("removeImageButton-".concat(String(i)));
            childSubDiv.id = "previewSubDiv-".concat(String(i - 1));
            childImage.id = "image-".concat(String(i - 1));
            childRemoveButton.id = "removeImageButton-".concat(String(i - 1));
          }
        }
        var arrayId = Number(previewSubDiv.id.split('-')[1]) - 1;
        this.imageArray.splice(arrayId, 1);
        this.originalImageArray.splice(arrayId, 1);
        this.previewNavigation();
        if (this.imageArray.length == 0) {
          this.previewDiv.style.display = "none";
          this.previewDiv.classList.remove("viewAllPreview");
          this.previewDiv.className += " preview";
          this.previewFuncDiv.style.display = "none";
          this.viewAll = false;
          this.viewAllDiv.classList.remove("viewAllPreviewDiv");
          this.viewAllDiv.className += " viewAllDiv";
          this.viewAllDivButton.classList.remove("viewAllDivPreviewButton");
          this.previewNavigation();
        }
      }

      var previewCanvas = this.drawCanvas(image, 1, PREVIEW_IMAGE_SIZE, undefined);
      previewCanvas.id = "image-1";
      previewCanvas.className = "previewCanvas";
      previewCanvas.onclick = () => {
        this.isDrawing = true;
        this.drawingImage = imageUrl;
        this.drawingCanvasId = previewCanvas.id;
        this.drawImageBox(imageUrl, orientation);
        this.navigation();
        //this.easyrtc.sendDataWS(peerEasyrtcid, SCREEN_SHARE, true);
      }
      previewSubDiv.appendChild(removeImageButton);
      previewSubDiv.appendChild(previewCanvas);
      if (this.previewDiv.hasChildNodes()) {
        this.previewDiv.insertBefore(previewSubDiv, this.previewDiv.childNodes[4]);
      } else {
        this.previewDiv.appendChild(previewSubDiv);
      }
      if (this.imageArray.length != 0 && !this.viewAll) {
        this.previewDiv.style.display = "block";
        this.previewFuncDiv.style.display = "flex";
      }
      if (this.viewAll) {
        this.calculateViewAllHeight();
      }
      this.previewNavigation();
      this.leftNavigationButton.onclick = () => {
        if (this.previewScrollIndex != 0) {
          this.previewScrollIndex--;
        }
        if (this.previewScrollIndex == 0) {
          this.previewDiv.scroll(0, 0);
        } else {
          this.previewDiv.scroll(70 + (this.previewScrollIndex * 165), 0);
        }
        this.previewNavigation();
      }
      this.rightNavigationButton.onclick = () => {
        this.previewScrollIndex++;
        this.previewDiv.scroll(70 + (this.previewScrollIndex * 165), 0);
        this.previewNavigation();
      }
    }
  }

  drawCanvas(img, multiplier, width, canvas) {
    var previewCanvas = document.createElement('canvas');
    if (canvas) {
      previewCanvas = canvas;
    }
    previewCanvas.width = width;
    var ctx = previewCanvas.getContext("2d");
    previewCanvas.height = previewCanvas.width * (img.height / img.width);
    var tmpImgeBox = $(document.getElementById("imageBox"));
    if (previewCanvas.height > tmpImgeBox.height()) {
      previewCanvas.height = tmpImgeBox.height();
      previewCanvas.width = tmpImgeBox.height() * (img.width / img.height);
    } else if (previewCanvas.width > tmpImgeBox.width()) {
      previewCanvas.width = tmpImgeBox.width();
      previewCanvas.height = tmpImgeBox.height() * (img.height / img.width);
    }
    var oc = document.createElement('canvas'),
      octx = oc.getContext('2d');
    oc.width = img.width * multiplier;
    oc.height = img.height * multiplier;
    if (!this.isBackCamera) {
      octx.save();
      octx.translate(0 + oc.width / 2, 0 + oc.height / 2);
      var rad = 2 * Math.PI - 0 * Math.PI / 180;
      octx.rotate(rad);
      octx.scale(-1, 1);
      octx.drawImage(img, -oc.width / 2, -oc.height / 2, oc.width, oc.height);
      octx.restore();
    } else {
      octx.drawImage(img, 0, 0, oc.width, oc.height);
    }
    octx.drawImage(oc, 0, 0, oc.width * multiplier, oc.height * multiplier);
    ctx.drawImage(oc, 0, 0, oc.width * multiplier, oc.height * multiplier,
      0, 0, previewCanvas.width, previewCanvas.height);
    return previewCanvas;
  }

  previewNavigation() {
    if ((this.imageArray.length - this.previewScrollIndex) <= 9) {
      this.rightNavigationButton.style.display = "none";
    } else {
      this.rightNavigationButton.style.display = "block";
    }
    if (this.previewScrollIndex == 0) {
      this.leftNavigationButton.style.display = "none";
    } else {
      this.leftNavigationButton.style.display = "block";
    }
  }

  /*
   * Annotation
   */
  navigation() {
    var arrayId = Number(this.drawingCanvasId.split('-')[1]);
    if (arrayId == 1) {
      this.previousButton.style.display = "none";
    } else {
      this.previousButton.style.display = "block";
    }
    if (arrayId == this.imageArray.length) {
      this.nextButton.style.display = "none";
    } else {
      this.nextButton.style.display = "block";
    }
    this.pagingText.innerText = arrayId + " of " + this.imageArray.length;
  }

  OnMouseDown(e) {
    var mouseX = e.pageX - this.drawingCanvas.getBoundingClientRect().left;
    var mouseY = e.pageY - this.drawingCanvas.getBoundingClientRect().top;
    this.paint = true;
    this.addClick(mouseX, mouseY);
    this.draw(this.drawingCanvas.getContext('2d'));
  }

  OnMouseMove(e) {
    if (this.paint) {
      this.addClick(e.pageX - this.drawingCanvas.getBoundingClientRect().left, e.pageY - this.drawingCanvas.getBoundingClientRect().top, true);
      this.draw(this.drawingCanvas.getContext('2d'));
    }
  }

  OnMouseUp(e) {
    this.paint = false;
    this.saveClick();
  }

  OnMouseLeave(e) {
    if (this.paint) {
      this.paint = false;
      this.saveClick();
    }
  }

  drawImageBox(content, orientation) {
    var imageBig = new Image();
    imageBig.src = content;
    imageBig.onload = () => {
      this.imageBox.style.display = "block";
      const canvas = document.querySelector('#takePhotoCanvas');
      if (orientation == 0) {
        this.drawingCanvas = this.drawCanvas(imageBig, 1, PORTRAIT_IMAGEBOX_SIZE, canvas);
      } else if (orientation == 90 || orientation == -90) {
        this.drawingCanvas = this.drawCanvas(imageBig, 1, LANDSCAPE_IMAGEBOX_SIZE, canvas);
      }
      this.imageBoxBehind.className = "imageBoxBehind";
      this.imageBoxBehind.onclick = () => {
        this.imageBox.style.display = "none";
        this.imageBoxBehind.classList.remove("imageBoxBehind");
        this.easyrtc.sendDataWS(this.peerEasyrtcid, SCREEN_SHARE, false);
      }

      while (this.palette.firstChild) {
        this.palette.removeChild(this.palette.firstChild);
      }
      for (let color of this.colorList) {
        this.colourButton = document.createElement("button");
        this.colourButton.className = "paletteButton";
        this.colourButton.style.backgroundColor = color;
        this.colourButton.onclick = () => {
          this.curColor = color;
        }
        this.palette.appendChild(this.colourButton);
      }
    }
  }

  addClick(x, y, dragging?) {
    this.clickX.push(x);
    this.clickY.push(y);
    this.clickDrag.push(dragging);
    this.clickColor.push(this.curColor);
  }

  saveClick() {
    this.undoClickX.push(this.clickX);
    this.undoClickY.push(this.clickY);
    this.undoClickDrag.push(this.clickDrag);
    this.undoClickColor.push(this.curColor);
    this.clickX = new Array();
    this.clickY = new Array();
    this.clickDrag = new Array();
    this.clickColor = new Array();
  }

  draw(context) {
    context.lineJoin = "round";
    context.lineWidth = 5;
    for (var i = 0; i < this.clickX.length; i++) {
      context.strokeStyle = this.curColor;
      context.beginPath();
      if (this.clickDrag[i] && i) {
        context.moveTo(this.clickX[i - 1], this.clickY[i - 1]);
      } else {
        context.moveTo(this.clickX[i] - 1, this.clickY[i]);
      }
      context.lineTo(this.clickX[i], this.clickY[i]);
      context.closePath();
      context.stroke();
    }
  }

  undoDraw(context) {
    for (var j = this.undoClickX.length - 2; j >= 0; j--) {
      var x = this.undoClickX[j];
      var y = this.undoClickY[j];
      var d = this.undoClickDrag[j];
      var c = this.undoClickColor[j];
      context.lineJoin = "round";
      context.lineWidth = 5;
      for (var i = 0; i < x.length; i++) {
        context.beginPath();
        if (d[i] && i) {
          context.moveTo(x[i - 1], y[i - 1]);
        } else {
          context.moveTo(x[i] - 1, y[i]);
        }
        context.lineTo(x[i], y[i]);
        context.closePath();
        context.strokeStyle = c;
        context.stroke();
      }
    }
    this.undoClickX.pop();
    this.undoClickY.pop();
    this.undoClickDrag.pop();
    this.undoClickColor.pop();
  }

  clearImageBox() {
    this.clearImageBoxStroke();
    this.drawingImage = undefined;
    this.drawingCanvasId = undefined;
    this.drawingCanvas = undefined;
  }

  clearImageBoxStroke() {
    this.clickX = new Array();
    this.clickY = new Array();
    this.clickDrag = new Array();
    this.clickColor = new Array();
    this.undoClickX = new Array();
    this.undoClickY = new Array();
    this.undoClickDrag = new Array();
    this.undoClickColor = new Array();
  }

  /* Screen Sharing */
  screenShare() {
    if (navigator.getDisplayMedia) {
      navigator.getDisplayMedia({ video: true }).then(stream => {
        this.onGettingSteam(stream);
      }, (error) => this.getDisplayMediaError(error)).catch((error) => this.getDisplayMediaError(error));
    }
  }

  onGettingSteam(stream) {
    this.screenSharingButton.className += " screenSharing";
    this.shareVideo.srcObject = stream;
    this.shareVideo.style.display = "block";
    this.easyrtc.addStreamStopListener(stream, () => {
      this.screenSharingButton.classList.remove("screenSharing");
      this.easyrtc.sendDataWS(this.peerEasyrtcid, STOP_SCREEN_SHARE, null);
    });
    this.easyrtc.addStreamToShare(this.peerEasyrtcid, stream, SCREEN_SHARE);
  }

  getDisplayMediaError(error) {
    console.log(error.toString());
  }

  /* View All Photos */
  viewAllPhoto() {
    if (this.viewAll) {
      this.viewAll = false;
      this.previewDiv.classList.remove("viewAllPreview");
      this.previewDiv.className += " preview";

      this.viewAllDiv.classList.remove("viewAllPreviewDiv");
      this.viewAllDiv.className += " viewAllDiv";

      this.previewFuncDiv.style.height = "200px";
      this.previewWrapFuncDiv.style.height = "200px";

      if (this.localeLabelBody) {
        this.viewAllDivButton.title = this.localeLabelBodyMap.get("viewAllPhoto") != null ? this.localeLabelBodyMap.get("viewAllPhoto") : this.viewAllDivButton.title;
      } else {
        this.viewAllDivButton.title = "打開";
      }
      this.viewAllDivButton.classList.remove("viewAllDivPreviewButton");
      this.previewNavigation();
    } else {
      this.viewAll = true;
      this.previewDiv.classList.remove("preview");
      this.previewDiv.className += " viewAllPreview";

      this.viewAllDiv.classList.remove("viewAllDiv");
      this.viewAllDiv.className += " viewAllPreviewDiv";

      this.previewFuncDiv.style.height = "90%";
      this.previewWrapFuncDiv.style.height = "100%";
      this.calculateViewAllHeight();

      if (this.localeLabelBody) {
        this.viewAllDivButton.title = this.localeLabelBodyMap.get("closeAllPhoto") != null ? this.localeLabelBodyMap.get("closeAllPhoto") : this.viewAllDivButton.title;
      } else {
        this.viewAllDivButton.title = "縮小";
      }
      this.viewAllDivButton.className += " viewAllDivPreviewButton";
      this.rightNavigationButton.style.display = "none";
      this.leftNavigationButton.style.display = "none";
    }
  }

  calculateViewAllHeight() {
    this.previewFuncDiv.style.height = "90%";
    if (this.imageArray.length > 24) {
      var current = Number(this.previewFuncDiv.style.height.substr(0, this.previewFuncDiv.style.height.indexOf("%")));
      var diff = this.imageArray.length - 24;
      var multiplier = Math.ceil(diff / 8);
      var checker = multiplier % 2 == 1 ? 30 : 31;
      var subTotal = checker * multiplier;
      var newHeight = current + subTotal;
      this.previewFuncDiv.style.height = newHeight + "%";
    }
  }

  /* Chat */
  sendChat() {
    let toSend = this.chatBoxInput.value;
    if (toSend && toSend.replace(/\s/g, "").length > 0) {
      if ((toSend.match(/\n/g) || []).length > 0) {
        toSend = toSend.replace(/\n/g, "<br />");
      }
      const chatTimestamp = new Date();
      this.appendAgentChatBox(toSend, chatTimestamp, true);
      this.chatBoxInput.value = "";
      this.easyrtc.sendDataWS(this.peerEasyrtcid, AGENT_CHAT, {
        contentMsg: toSend,
        sentOn: chatTimestamp
      });
      this.storeChatOnSession(toSend, chatTimestamp, true);
      this.persistAgentChat(toSend, chatTimestamp);
    }
  }

  openChat() {
    this.isChatBoxOpen = true;
    this.unreadChatCount = 0;
    this.unreadChatCountSpan.innerHTML = "";
    this.unreadChatIdentifier.className = "hide";
    this.openChatButton.style.opacity = "0";
    this.openChatButton.classList.remove("hide");
    this.openChatButton.className += " hide";
    this.chatSection.classList.remove("hide");
    setTimeout(() => {
      this.chatSection.style.left = "0";
      this.updateChatScroll();
    }, 50);
  }

  closeChat() {
    this.isChatBoxOpen = false;
    this.chatSection.style.left = "-50%";
    setTimeout(() => {
      this.openChatButton.classList.remove("hide");
      this.openChatButton.style.opacity = "1";
      this.chatSection.className = "hide";
    }, 100);
  }

  appendAgentChatBox(msg, chatTimestamp, isAgent) {
    let time = new Date(chatTimestamp);
    let chatRow = document.createElement("div");
    chatRow.className = `${isAgent ? "chatAgent" : "chatClaimant"}`;
    let chatLabel = document.createElement("div");
    chatLabel.className = "chatLabel";
    chatLabel.innerHTML = isAgent ? this.conversationAgent : this.conversationClaimant;
    chatRow.appendChild(chatLabel);
    let chatContentTimestamp = document.createElement("div");
    chatContentTimestamp.className = "chatContentTimestamp";
    let time_h = this.formatTo2Digits(time.getHours());
    let time_m = this.formatTo2Digits(time.getMinutes());
    let time_s = this.formatTo2Digits(time.getSeconds());
    chatContentTimestamp.innerHTML = `${time_h}:${time_m}:${time_s}`;
    chatRow.appendChild(chatContentTimestamp);
    let chatContent = document.createElement("div");
    chatContent.className = "chatContent";
    chatContent.innerHTML = msg;
    chatRow.appendChild(chatContent);
    let chatBoxBody = document.getElementById("chatBoxBody");
    chatBoxBody.appendChild(chatRow);
    ++this.chatCount;
    this.updateChatScroll();
  }

  updateChatScroll() {
    $(this.chatBoxBody).animate({
      scrollTop: this.chatBoxBody.scrollHeight
    });
  }

  retrieveAndSetupChatSession() {
    let chatHistory = sessionStorage.getItem(this.roomHash);
    if (chatHistory) {
      while (this.chatBoxBody.firstChild) {
        this.chatBoxBody.removeChild(this.chatBoxBody.firstChild);
      }
      let chatHistoryObj = JSON.parse(chatHistory);
      for (let i = 0; i < chatHistoryObj.length; i++) {
        let chat = chatHistoryObj[i];
        this.appendAgentChatBox(chat.contentMsg, chat.sentOn, chat.isAgent);
      }
    }
  }

  storeChatOnSession(chatContent, chatTimestamp, isAgent) {
    let newChatLine = {
      contentMsg: chatContent,
      sentOn: chatTimestamp,
      isAgent: isAgent
    }
    let chatHistory = sessionStorage.getItem(this.roomHash);
    if (chatHistory) {
      let newChatHistory = JSON.parse(chatHistory);
      if (newChatHistory.length >= MAX_CHAT_STORED) {
        newChatHistory.splice(0, 1);
      }
      newChatHistory.push(newChatLine);
      sessionStorage.setItem(this.roomHash, JSON.stringify(newChatHistory));
    } else {
      sessionStorage.setItem(this.roomHash, JSON.stringify([newChatLine]));
    }
  }

  receiveChatEvent(receivedContent) {
    if (!this.isChatBoxOpen) {
      ++this.unreadChatCount;
      this.unreadChatCountSpan.innerHTML = String(this.unreadChatCount);
      this.unreadChatIdentifier.classList.remove("hide");
    }
    this.storeChatOnSession(receivedContent.contentMsg, receivedContent.sentOn, false);
    this.appendAgentChatBox(receivedContent.contentMsg, receivedContent.sentOn, false);
  }


  /* Video Recording */
  startSelfVideoRecording() {
    if (!this.selfRecording) {
      this.selfRecording = true;
      this.selfMediaRecorder = this.easyrtcRecorder.recordToZip(this.selfRecordStream, true);
      if (!this.selfMediaRecorder) {
        console.log("Failed to start recorder for self");
      }
    }
  }

  endSelfVideoRecording() {
    if (this.selfRecording && this.selfMediaRecorder) {
      this.selfRecording = false;
      this.selfMediaRecorder.stop();
    }
  }

  startCallerVideoRecording() {
    if (!this.callerRecording) {
      this.callerRecording = true;
      this.callerMediaRecorder = this.easyrtcRecorder.recordToZip(this.callerRecordStream, false);
      if (!this.callerMediaRecorder) {
        console.log("Failed to start recorder for caller");
      }
    }
  }

  endCallerVideoRecording() {
    if (this.callerRecording && this.callerMediaRecorder) {
      this.callerRecording = false;
      this.callerMediaRecorder.stop();
    }
  }

  endVideoRecording() {
    this.easyrtcRecorder.enableEndRecording(this.enableEndRecording.bind(this));
    this.endSelfVideoRecording();
    this.endCallerVideoRecording();
  }

  enableEndRecording() {
    setTimeout(async () => {
      await this.downloadZip().then(() => {
        this.uploadVideoRecorded();
      });
    }, 500);
  }

  downloadZip() {
    let zipFile: JSZip = new JSZip();
    let zipFileUtil = JSZipUtils;
    let fileSaver = FileSaver;
    return this.easyrtcRecorder.getZipFileWithUrls(zipFile, zipFileUtil, fileSaver, "Truesight_RecordedVideo_" + this.roomHash);
  }

  /* Geolocation */
  generateMap(latitude, longitude) {
    this.mapBox.style.display = "block";
    this.mapBoxBehind.className = "mapBoxBehind";
    this.mapBoxBehind.onclick = () => {
      this.mapBox.style.display = "none";
      this.mapBoxBehind.classList.remove("mapBoxBehind");
    }
    var map;
    var myLatlng = new google.maps.LatLng(latitude, longitude);
    var myOptions = {
      zoom: 14,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }
    map = new google.maps.Map(document.getElementById("map"), myOptions);
    var marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
    });
    this.closeMapButton = document.createElement("button");
    this.closeMapButton.id = "closeMapButton";
    this.closeMapButton.title = "關閉";
    this.closeMapButton.className = "mapButtons";
    this.closeMapButton.onclick = () => {
      this.mapBox.style.display = "none";
      this.mapBoxBehind.classList.remove("mapBoxBehind");
    }
    while (this.mapFuncDiv.hasChildNodes()) {
      this.mapFuncDiv.removeChild(this.mapFuncDiv.lastChild);
    }
    setTimeout(() => {
      this.mapFuncDiv.appendChild(this.closeMapButton);
    }, 1000);
  }

  /* API Call */
  getCountryCode() {
    this.restService.getCountryCode().subscribe((data: any) => {
      this.countryCodeBody = data;
      this.getLanguage();
    });
  }

  getLanguage() {
    var isoCode;
    if (this.SKIP_CHECKING_LANGUAGE) {
      isoCode = "HK";
    } else {
      for (var i = 0; i < this.countryCodeBody.length; i++) {
        console.log("countryCode:", this.countryCode);
        if (this.countryCodeBody[i].code === this.countryCode) {
          isoCode = this.countryCodeBody[i].isoCode;
          break;
        }
      }
    }

    this.restService.getLanguageByCountryCode(isoCode).subscribe((data: any) => {
      if (this.countryCodeCounter === 3) {
      } else if (data !== null && data.length === 0) {
        this.countryCodeCounter++;
        this.SKIP_CHECKING_LANGUAGE = true;
        this.getLanguage();
      } else {
        this.countryCodeCounter = 0;
        this.easyrtc.sendDataWS(this.peerEasyrtcid, LANGUAGE, data);
        this.localeLabelBody = data;
        this.changeLanguage();
      }
    });
  }

  uploadImageService() {
    if (this.imageArray.length == 0) {
      this.updateRoomData();
    } else {
      for (var i = 0; i < this.imageArray.length; i++) {
        const claimPhoto = new ClaimPhotoModel();
        claimPhoto.imageUrl = "";
        claimPhoto.imageContentType = IMAGEFORMAT;
        claimPhoto.image = this.imageArray[i].split(',')[1];
        claimPhoto.claimReferenceNo = this.referenceNo;
        claimPhoto.isUnreadImage = false;
        this.restService.createClaimPhoto(claimPhoto).subscribe((data: any) => {
          if (i == this.imageArray.length) {
            this.updateRoomData();
          }
        });
      }
    }
  };

  updateRoomData() {
    this.agentProfile.status = AgentStatus.ACTIVE;
    this.restService.updateAgentProfile(this.agentProfile).subscribe(() => {
      let claimHistory = new ClaimHistoryModel();
      claimHistory = this.responseBody;
      claimHistory.referenceNo = this.referenceNo;
      claimHistory.latitude = this.latitude;
      claimHistory.longitude = this.longitude;
      claimHistory.deviceInfo = this.osVersion + "|" + this.browserVersion;
      claimHistory.status = AppointmentStatus.COMPLETED;
      this.restService.createClaimHistoryClosure(claimHistory).subscribe((data: any) => {
        if (data) {
          window.close();
          // this.endVideoRecording();
        }
      });
    });
  }

  uploadVideoRecorded() {
    this.restService.createClaimVideo(this.referenceNo, this.easyrtcRecorder.getZipFileUrl()).subscribe((data: any) => {
      if (data) {
        window.close();
      }
    });
  };

  persistAgentChat(chatContent, chatTimestamp) {
    const chatHistory = new ChatHistoryModel();
    chatHistory.claimReferenceNo = this.referenceNo;
    chatHistory.content = chatContent;
    chatHistory.isAgent = true;
    chatHistory.sentOn = chatTimestamp;
    chatHistory.isUnread = false;
    chatHistory.isImage = false;
    this.restService.createChatHistory(chatHistory).subscribe((data: any) => {
      console.log("success create chat history.");
    });
  }

  changeLanguage() {
    for (var i = 0; i < this.localeLabelBody.length; i++) {
      this.localeLabelBodyMap.set(this.localeLabelBody[i].label, this.localeLabelBody[i].description);
    }
    document.title = this.localeLabelBodyMap.get("agentTitleTab") != null ? this.localeLabelBodyMap.get("agentTitleTab") : document.title;
    this.conversationAgent = this.localeLabelBodyMap.get("conversationAgent") != null ? this.localeLabelBodyMap.get("conversationAgent") : this.conversationAgent;
    this.conversationClaimant = this.localeLabelBodyMap.get("conversationClaimant") != null ? this.localeLabelBodyMap.get("conversationClaimant") : this.conversationClaimant;
    this.chatBoxInput.placeholder = this.localeLabelBodyMap.get("conversationText") != null ? this.localeLabelBodyMap.get("conversationText") : this.conversationText;
    this.changeCameraButton.title = this.localeLabelBodyMap.get("changeCameraButton") != null ? this.localeLabelBodyMap.get("changeCameraButton") : this.changeCameraButton.title;
    this.mutedFlashButton.title = this.localeLabelBodyMap.get("flashButton") != null ? this.localeLabelBodyMap.get("flashButton") : this.flashButton.title;
    this.flashButton.title = this.localeLabelBodyMap.get("flashButton") != null ? this.localeLabelBodyMap.get("flashButton") : this.flashButton.title;
    this.zoomSlider.title = this.localeLabelBodyMap.get("zoomRangeSlider") != null ? this.localeLabelBodyMap.get("zoomRangeSlider") : this.zoomSlider.title;
    // this.screenShareButton.title = this.localeLabelBodyMap.get("screenShareButton") != null ? this.localeLabelBodyMap.get("screenShareButton") : this.screenShareButton.title;

    this.gpsTrackerButton.title = this.localeLabelBodyMap.get("gpsTrackerButton") != null ? this.localeLabelBodyMap.get("gpsTrackerButton") : this.gpsTrackerButton.title;
    this.takePhotoButton.title = this.localeLabelBodyMap.get("takePhotoButton") != null ? this.localeLabelBodyMap.get("takePhotoButton") : this.takePhotoButton.title;
    this.previousButton.title = this.localeLabelBodyMap.get("previousImageButton") != null ? this.localeLabelBodyMap.get("previousImageButton") : this.previousButton.title;
    this.nextButton.title = this.localeLabelBodyMap.get("nextImageButton") != null ? this.localeLabelBodyMap.get("nextImageButton") : this.nextButton.title;
    this.undoButton.title = this.localeLabelBodyMap.get("undoButton") != null ? this.localeLabelBodyMap.get("undoButton") : this.undoButton.title;
    this.saveButton.title = this.localeLabelBodyMap.get("saveButton") != null ? this.localeLabelBodyMap.get("saveButton") : this.saveButton.title;
    this.leftNavigationButton.title = this.localeLabelBodyMap.get("leftNavigationButton") != null ? this.localeLabelBodyMap.get("leftNavigationButton") : this.leftNavigationButton.title;
    this.rightNavigationButton.title = this.localeLabelBodyMap.get("rightNavigationButton") != null ? this.localeLabelBodyMap.get("rightNavigationButton") : this.rightNavigationButton.title;
    this.closeButton.title = this.localeLabelBodyMap.get("closeButton") != null ? this.localeLabelBodyMap.get("closeButton") : this.closeButton.title;
    this.hangupButton.title = this.localeLabelBodyMap.get("hangupButton") != null ? this.localeLabelBodyMap.get("hangupButton") : this.hangupButton.title;
    this.sendImagesButton.title = this.localeLabelBodyMap.get("sendImageButton") != null ? this.localeLabelBodyMap.get("sendImageButton") : this.sendImagesButton.title;

    if (this.play) {
      this.pauseVideoButton.title = this.localeLabelBodyMap.get("pauseVideoButton") != null ? this.localeLabelBodyMap.get("pauseVideoButton") : this.pauseVideoButton.title;
      this.voiceButton.title = this.localeLabelBodyMap.get("switchOffVoiceButton") != null ? this.localeLabelBodyMap.get("switchOffVoiceButton") : this.voiceButton.title;
      this.mutedVoiceButton.title = this.localeLabelBodyMap.get("switchOffVoiceButton") != null ? this.localeLabelBodyMap.get("switchOffVoiceButton") : this.mutedVoiceButton.title;
    } else {
      this.pauseVideoButton.title = this.localeLabelBodyMap.get("playVideoButton") != null ? this.localeLabelBodyMap.get("playVideoButton") : this.pauseVideoButton.title;
      this.voiceButton.title = this.localeLabelBodyMap.get("switchOnVoiceButton") != null ? this.localeLabelBodyMap.get("switchOnVoiceButton") : this.voiceButton.title;
      this.mutedVoiceButton.title = this.localeLabelBodyMap.get("switchOnVoiceButton") != null ? this.localeLabelBodyMap.get("switchOnVoiceButton") : this.mutedVoiceButton.title;
    }

    if (this.voiceMuted) {
      this.voiceButton.title = this.localeLabelBodyMap.get("switchOnVoiceButton") != null ? this.localeLabelBodyMap.get("switchOnVoiceButton") : this.voiceButton.title;
      this.mutedVoiceButton.title = this.localeLabelBodyMap.get("switchOnVoiceButton") != null ? this.localeLabelBodyMap.get("switchOnVoiceButton") : this.mutedVoiceButton.title;
    } else {
      this.voiceButton.title = this.localeLabelBodyMap.get("switchOffVoiceButton") != null ? this.localeLabelBodyMap.get("switchOffVoiceButton") : this.voiceButton.title;
      this.mutedVoiceButton.title = this.localeLabelBodyMap.get("switchOffVoiceButton") != null ? this.localeLabelBodyMap.get("switchOffVoiceButton") : this.mutedVoiceButton.title;
    }

    if (this.videoMuted) {
      this.videoButton.title = this.localeLabelBodyMap.get("switchOnVideoButton") != null ? this.localeLabelBodyMap.get("switchOnVideoButton") : this.videoButton.title;
      this.mutedVideoButton.title = this.localeLabelBodyMap.get("switchOnVideoButton") != null ? this.localeLabelBodyMap.get("switchOnVideoButton") : this.mutedVideoButton.title;
    } else {
      this.videoButton.title = this.localeLabelBodyMap.get("switchOffVideoButton") != null ? this.localeLabelBodyMap.get("switchOffVideoButton") : this.videoButton.title;
      this.mutedVideoButton.title = this.localeLabelBodyMap.get("switchOffVideoButton") != null ? this.localeLabelBodyMap.get("switchOffVideoButton") : this.mutedVideoButton.title;
    }

    if (this.viewAll) {
      this.viewAllDivButton.title = this.localeLabelBodyMap.get("closeAllPhoto") != null ? this.localeLabelBodyMap.get("closeAllPhoto") : this.viewAllDivButton.title;
    } else {
      this.viewAllDivButton.title = this.localeLabelBodyMap.get("viewAllPhoto") != null ? this.localeLabelBodyMap.get("viewAllPhoto") : this.viewAllDivButton.title;
    }
  }

  // @HostListener("window:beforeunload", ["$event"])
  // beforeUnloadHandler($event: any) {
  //   $event.returnValue = true;
  // }

  // @HostListener("window:unload")
  // unloadHandler() {
  //   if (this.agentProfile) {
  //     this.agentProfile.status = AgentStatus.ACTIVE;
  //     $.ajax({
  //       type: "PUT",
  //       async: false,
  //       url: `${environment.apiUrl}${RestUrl.API_URL}${RestUrl.AGENT_PROFILE}`,
  //       contentType: "application/json",
  //       data: JSON.stringify(this.agentProfile)
  //     });
  //   }
  // }
}
