import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideoCallService {

  isBtnTrigger: string;

  hangup: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }
}
