import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../service/translate.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) { }

  transform(key: string, args?: any[]): string {
    let translation = this.getTranslation(key);

    if (args && args.length > 0) {
      for (let i = 0; i < args.length; i++) {
        translation = translation.replace("{" + i + "}", this.getTranslation(args[i]));
      }
    }

    return translation;
  }

  getTranslation(key: string): string {
    let k: Array<string> = key.split('.');

    switch (k.length) {
      case 1:
        return this.translateService.data[k[0]] || key;

      case 2:
        return this.translateService.data[k[0]][k[1]] || key;

      case 3:
        return this.translateService.data[k[0]][k[1]][k[2]] || key;

      case 4:
        return this.translateService.data[k[0]][k[1]][k[2]][k[3]] || key;

      case 5:
        return this.translateService.data[k[0]][k[1]][k[2]][k[3]][k[4]] || key;

      default:
        return key;
    }
  }

}
