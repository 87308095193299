import { Pipe, PipeTransform } from '@angular/core';
import { RegularExpressionConstant, RestrictInputRegularExpressionConstant } from '../constant/regular-expression-constant';

@Pipe({
  name: 'regex'
})
export class RegexPipe implements PipeTransform {

  transform(key: string, args?: any): any {
    switch (key) {
      case "number":
        return RegularExpressionConstant.number;
      case "alphanumeric":
        return RegularExpressionConstant.alphanumeric;
      case "password":
        return RegularExpressionConstant.password;
      case "alphanumericInput":
        return RestrictInputRegularExpressionConstant.alphanumeric;
      case "numberInput":
        return RestrictInputRegularExpressionConstant.number;
    }
  }
}
