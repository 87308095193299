import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseService } from './service/base.service';
import { SessionService } from './service/common/session.service';
import { SharedService } from './service/common/shared.service';
import { ErrorsHandlerService } from './service/error/errors-handler.service';
import { InitService } from './service/init/init.service';
import { TranslateService } from './service/translate.service';
import { CommonModalService } from './view/modal/common-modal-service';
import { ConfirmContentComponent } from './view/modal/confirm-content/confirm-content.component';
import { ErrorContentComponent } from './view/modal/error-content/error-content.component';
import { LoaderComponent } from './view/modal/loader/loader.component';
import { SharedModule } from './view/shared/shared.module';
import { AgentComponent } from './view/agent/agent.component';
import { KeysPipe } from './pipes/keys.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

export function setupTranslateFactory(translateService: TranslateService): Function {
  window.localStorage.setItem('lang', 'en');
  return () => {
    if (!window.localStorage.getItem('lang')) {
      window.localStorage.setItem('lang', 'en');
    }

    translateService.use(window.localStorage.getItem('lang'));
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmContentComponent,
    ErrorContentComponent,
    LoaderComponent,
    AgentComponent,
    KeysPipe,
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    TimepickerModule.forRoot(),
  ],
  providers: [
    BaseService,
    CommonModalService,
    DatePipe,
    InitService,
    SessionService,
    SharedService,
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    { provide: ErrorHandler, useClass: ErrorsHandlerService }
  ],
  entryComponents: [ErrorContentComponent, ConfirmContentComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
