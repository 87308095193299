import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandsSeparator'
})
export class ThousandsSeparatorPipe implements PipeTransform {

  transform(value: any, delimiter: string = ','): string {
    if (value === undefined || value === null || value === '' || typeof value === 'number' && isNaN(value)) {
      return value;
    }

    let valueStr = value;

    if (typeof valueStr !== 'string') {
      valueStr = value.toString();
    }

    let splitValueStr = valueStr.split('.'); // To handle decimals
    splitValueStr[0] = splitValueStr[0].replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
    return splitValueStr.join('.');
  }

}
