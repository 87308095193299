import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, forwardRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpStatusConstant } from '../constant/http-status-constant';
import { RouteConstant } from '../constant/route-constant';
import { RequestModel } from '../model/base/request-body-model';
import { RequestHeaderModel } from '../model/base/request-header-model';
import { CommonModalService } from '../view/modal/common-modal-service';
import { SessionService } from './common/session.service';

export class BaseService {

  showPopupEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(forwardRef(() => HttpClient)) public http: HttpClient,
    @Inject(forwardRef(() => CommonModalService)) public commonModalService: CommonModalService,
    @Inject(forwardRef(() => Router)) private router: Router,
    @Inject(forwardRef(() => SessionService)) public sessionService: SessionService
  ) { }

  showLoadIndicator(showLoadIndicator: boolean): void {
    if (showLoadIndicator === undefined || showLoadIndicator === null) {
      this.commonModalService.showLoader.emit(true);
    } else {
      this.commonModalService.showLoader.emit(showLoadIndicator);
    }
  }

  get(url: string, params?: HttpParams, showLoadIndicator?: boolean): Observable<any> {
    this.showLoadIndicator(showLoadIndicator);
    return this.http.get(url, this.getHttpOptions(params))
      .pipe(
        map((res: Response) => this.handleSuccess(res)),
        catchError((error: any) => this.catchError(error))
      );
  }

  post(url: string, body: any, params?: HttpParams, showLoadIndicator?: boolean): Observable<any> {
    this.showLoadIndicator(showLoadIndicator);
    this.buildRequestHeader(body);
    return this.http.post(url, body, this.getHttpOptions(params, body, url))
      .pipe(
        map((res: Response) => this.handleSuccess(res)),
        catchError((error: any) => this.catchError(error))
      );
  }

  put(url: string, body: any, params?: HttpParams, showLoadIndicator?: boolean): Observable<any> {
    this.showLoadIndicator(showLoadIndicator);
    this.buildRequestHeader(body);
    return this.http.put(url, body, this.getHttpOptions(params, body, url))
      .pipe(
        map((res: Response) => this.handleSuccess(res)),
        catchError((error: any) => this.catchError(error))
      );
  }

  delete(url: string, params?: HttpParams, showLoadIndicator?: boolean): Observable<any> {
    this.showLoadIndicator(showLoadIndicator);
    return this.http.delete(url, this.getHttpOptions(params))
      .pipe(
        map((res: Response) => this.handleSuccess(res)),
        catchError((error: any) => this.catchError(error))
      );
  }

  private buildRequestHeader(body: RequestModel<any>) {
    if (body) {
      body.requestHeader = new RequestHeaderModel();
      let date = new Date();

      body.requestHeader.deviceId = this.sessionService.getDeviceId();
      body.requestHeader.channelTime = date.toISOString();
    }
  }

  private getHttpOptions(params?: HttpParams, body?: any, url?: string) {
    let excludeApi = 'public/init';
    let oauthToken: string = null;
    let oauthSession = null;
    // let oauthSession = this.sessionService.oauthSession;
    // if (this.sessionService.oauthSession) {
    //   oauthToken = oauthSession.token_type + ' ' + oauthSession.access_token;
    // }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': oauthSession != null ? oauthToken : environment.authorization,
      // 'Channel-Auth-Code': body && url.indexOf(excludeApi) < 0 ?
      //   this.getHashMAC(body, this.sessionService.getHashKey()) : '',
      'accept-language': this.setAcceptLanguage()
    });
    return { headers, params };
  }

  private setAcceptLanguage(): string {
    let lang = window.localStorage.getItem('lang');

    if (lang === 'en') {
      return 'en-US';
    } else if (lang === 'bm') {
      return 'ms-MY';
    }
  }

  private getHashMAC(payload: any, hashKey: string): any {
    let request: RequestModel<any> = payload;
    console.log('Timestamp', request.requestHeader.channelTime);
    console.log('HashKey', hashKey);
    console.log('Payload', payload);

    let hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, hashKey);
    hmac.update(JSON.stringify(payload));
    hmac.update(request.requestHeader.channelTime);
    let cryptoResult = hmac.finalize().toString(CryptoJS.enc.Base64);

    console.log('HMAC Generated ', cryptoResult);

    return cryptoResult;
  }

  private handleSuccess(res: Response, hideLoadIndicator?: boolean) {
    let response: any = res;
    if (response && response.error) {
      throw res;
    } else {
      if (hideLoadIndicator !== false) {
        this.commonModalService.showLoader.emit(false);
      }

      console.log('handleSuccess', res);
      return res;
    }
  }

  /**
   * To fix error:
   * 
   * You provided an invalid object where a stream was expected.
   * You can provide an Observable, Promise, Array, or Iterable.
   */
  private catchError(error: any) {
    this.handleError(error);
    return error;
  }

  private handleError(error: any) {
    this.commonModalService.showLoader.emit(false);
    console.log('handleError');
    console.log(error);

    if (error.status != undefined) {
      if (error.status === HttpStatusConstant.UNKNOWN) {
        console.log('UNKNOWN');
        this.showOfflineErrorPopup();
        // this.sessionService.clearUserProfile();
        // this.router.navigate([RouteConstant.PUBLIC], { skipLocationChange: true });
      } else if (error.status === HttpStatusConstant.UNAUTHORIZED) {
        console.log('UNAUTHORIZED');
        this.showErrorPopup(error.error);
        //this.sessionService.oauthSession = null;
        // this.sessionService.clearUserProfile();
        // this.router.navigate([RouteConstant.PUBLIC_LOGIN], { skipLocationChange: true });
      } else if (error.status === HttpStatusConstant.PERMANENT_REDIRECT) {
        console.log('PERMANENT_REDIRECT');
        this.showErrorPopup(error.error);
      } else if (error.status === HttpStatusConstant.NOT_FOUND) {
        console.log('Not Found');
        this.showErrorPopup('Page Not Found'); // TODO perform redirect?
      } else if (error.status === HttpStatusConstant.INTERNAL_ERROR) {
        console.log('Server Internal Error'); // TODO perform redirect?
        if (error.error) {
          this.showErrorPopup(error.error);
        } else {
          this.showErrorPopup('Server Internal Error'); // TODO perform redirect?
        }
      } else if (error.status === HttpStatusConstant.SERVICE_UNAVAILABLE) {
        console.log('SERVICE UNAVAILABLE'); // TODO perform redirect?
        this.showErrorPopup(error.error);

        if (this.router.url.indexOf(RouteConstant.SECURE) !== -1) {
          // check global downtime then redirect to public
          console.log('SERVICE UNAVAILABLE ERROR MESSAGE ' + JSON.stringify(error));
          console.log('Route to PUBLIC_LOGIN');
          this.sessionService.clearUserProfile();
          this.router.navigate([RouteConstant.PUBLIC_LOGIN], { skipLocationChange: true });
        }
      } else if (error.status === HttpStatusConstant.BAD_REQUEST) {
        console.log('BAD REQUEST');
        if ('noActiveAgent'.match(error.error.errorKey)) {
          error.error.notShowDesc = true;
        }
        this.showErrorPopup(error.error);
      } else {
        this.showErrorPopup(error);
      }
    } else {
      if (error.error && error.message) {
        this.showErrorPopup(error);
      } else {
        this.showErrorPopup('Sorry, we\'ve encountered an unexpected error. Please try again. If this continues, please contact our Call Centre to report this issue.');
      }
    }

    throw error;
  }

  showOfflineErrorPopup(): void {
    let response: any = {
      title: 'Connection Fail',
      error: 'Please check your internet connection.'
    };
    this.showErrorPopup(response);
  }

  showErrorPopup(error: any): void {
    this.commonModalService.showErrorPopup.emit(error);
  }

}
