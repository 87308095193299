import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appAmountValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: AmountValidatorDirective, multi: true }]
})
export class AmountValidatorDirective {

  constructor() { }

  public validate(control: AbstractControl): { [key: string]: any } {
    let amountRegEx = /^[0-9]+(\.[0-9]{1,2})?$/;
    let valid = amountRegEx.test(control.value);
    return control.value < 1 || valid ? null : { 'appAmountValidator': true };
  }
}
