import { Directive, OnInit, Input, HostListener, SimpleChanges, OnChanges } from '@angular/core';

declare global {
    interface Window {
        clipboardData: any; // To suppress TypeScript error during `ng build`
    }
}

@Directive({
    selector: '[appRestrictInputTo]'
})
export class RestrictInputToDirective implements OnInit, OnChanges {
    @Input('appRestrictInputTo') regexpString: string;
    regexp: RegExp;

    constructor() {
    }

    ngOnInit() {
        this.regexp = new RegExp(this.regexpString);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.regexp = new RegExp(changes.regexpString.currentValue);
    }

    /*
     * The typical way to prevent a key input is to actually listen to the
     * `keydown` event, and then use `event.preventDefault()` whenever we
     * see that the undesired is being pressed.
     *
     * Unfortunately, that method does NOT work on Android, which is why
     * we have to listen to the `input` event, and then manually remove the
     * special characters from the input value.
     */
    @HostListener('input', ['$event']) onKeyDown(event) {
        let input = event.target;
        let inputValue = input.value;
        let splitInputValue = inputValue.split('');
        let filteredSplitInputValue = splitInputValue.map((character) => {
            if (!this.regexp.test(character)) {
                return '';
            } else {
                return character;
            }
        });
        let thereAreFilteredCharacters = filteredSplitInputValue.filter(character => !character).length;

        /*
         * Need to check if we have to filter any characters from the input,
         * otherwise we will end up in an infinite recursive loop due to the
         * need to trigger the `input` event again in order to make the Angular
         * form update its validity.
         */
        if (thereAreFilteredCharacters) {
            let selectionStart = input.selectionStart;
            let inputValueBeforeSelectionStart = filteredSplitInputValue.slice(0, selectionStart);
            let totalCharactersRemovedBeforeSelectionStart = inputValueBeforeSelectionStart.filter(character => !character).length;
            let newSelectionStart = selectionStart - totalCharactersRemovedBeforeSelectionStart;
            let filteredInputValue = filteredSplitInputValue.join('');

            input.value = filteredInputValue;
            input.selectionStart = newSelectionStart;
            input.selectionEnd = newSelectionStart;

            /*
             * Manually trigger the `input` event so that the Angular form
             * will update its validity.
             */
            this.triggerEvent('input', input);
        }
    }

    @HostListener('paste', ['$event']) onPaste(event) {
        event.preventDefault();

        let pasteData = (event.clipboardData)
            ? event.clipboardData.getData('text') // For all desktop browsers except IE
            : window.clipboardData.getData('text'); // For mobile browsers and desktop IE
        let filteredSplitPasteData = pasteData.split('').filter((letter) => {
            return this.regexp.test(letter);
        });
        let filteredPasteData = filteredSplitPasteData.join('');
        let input = event.target;
        let inputValue = input.value;
        let inputSelectionStart = input.selectionStart;
        let inputFrontValue = inputValue.substring(0, inputSelectionStart);
        let inputBackValue = inputValue.substring(inputSelectionStart);
        let inputFinalValue = inputFrontValue + filteredPasteData + inputBackValue;

        if (typeof input.maxLength === 'number') {
            inputFinalValue = inputFinalValue.substr(0, input.maxLength);
        }

        input.value = inputFinalValue;
        input.selectionStart = inputSelectionStart + filteredPasteData.length;
        input.selectionEnd = input.selectionStart;

        /*
         * Manually trigger the `input` event so that the Angular form
         * will update its validity.
         */
        this.triggerEvent('input', input);
    }

    triggerEvent(eventType: string, element) {
        const customEvent = document.createEvent('CustomEvent');
        customEvent.initEvent(eventType, true, true);
        element.dispatchEvent(customEvent);
    }
}
