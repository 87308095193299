import { forwardRef, Inject, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { TranslateService } from '../translate.service';

declare var RSAKey: any;

export class SharedService {

  constructor(
    @Inject(forwardRef(() => NgZone)) private ngZone: NgZone,
    @Inject(forwardRef(() => Router)) private router: Router,
    @Inject(forwardRef(() => TranslateService)) private translateService: TranslateService
  ) { }

  /**
   * Encrypt the plaintext with using public key - RSA2048 encryption
   * 
   * @param plainText plain text used for encryption
   */
  encryptValues(plainText: string): string {
    let nvalue = environment.nvalue;
    let evalue = environment.evalue;

    let rsa = new RSAKey();
    rsa.setPublic(nvalue, evalue);
    let res = rsa.encrypt(plainText);

    return res;
  }

  /**
   * Generate random UUID 
   */
  generateUuid(): string {
    let id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
    return id;
  }

  /**
   * Perform routing to specific URL
   * 
   * @param url string
   */
  navigate(url: string): void {
    this.ngZone.run(() => this.router.navigate([url])).then();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  setNewDateForCategorization(array: Array<any>): Array<any> {
    let setArray: Array<any> = new Array<any>();

    array.forEach(element => {
      let newDate = { date: element.date };
      setArray.push(Object.assign(element, newDate));
    });

    return setArray;
  }

  categorize(array: Array<any>): Array<ListItemModel> {
    console.log('array', array, this.translateService.data);
    let categorizedList: Array<ListItemModel> = [
      {
        range: this.translateService.data['TABLES']['TODAY'],
        items: []
      },
      {
        range: this.translateService.data['TABLES']['YESTERDAY'],
        items: []
      },
      {
        range: this.translateService.data['TABLES']['THIS_WEEK'],
        items: []
      },
      {
        range: this.translateService.data['TABLES']['THIS_MONTH'],
        items: []
      },
      {
        range: this.translateService.data['TABLES']['THIS_YEAR'],
        items: []
      },
      {
        range: this.translateService.data['TABLES']['A_LONG_TIME_AGO'],
        items: []
      }
    ];

    let today: any = new Date().setHours(0, 0, 0, 0);
    let yesterday: any = new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0);
    let week: any = new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0, 0);
    let month: any = new Date(new Date().setMonth(new Date().getMonth() - 1));
    month = new Date(month.setDate(month.getDate() + 1)).setHours(0, 0, 0, 0);
    let year: any = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    year = new Date(year.setDate(year.getDate() + 1)).setHours(0, 0, 0, 0);

    // Sort elements into categories
    array.forEach(element => {
      let elementDate = new Date(element.date).setHours(0, 0, 0, 0);

      // Today
      if (elementDate === today) {
        categorizedList[0].items.push(element);
      }

      // Yesterday
      else if (elementDate === yesterday) {
        categorizedList[1].items.push(element);
      }

      // This Week
      else if (elementDate >= week && elementDate < yesterday) {
        categorizedList[2].items.push(element);
      }

      // This Month
      else if (elementDate >= month && elementDate < week) {
        categorizedList[3].items.push(element);
      }

      // This Year
      else if (elementDate >= year && elementDate < month) {
        categorizedList[4].items.push(element);
      }

      // A Long Time Ago
      else if (elementDate < year) {
        categorizedList[5].items.push(element);
      }
    });

    // Remove empty categories
    let i: number = 0;
    while (i < categorizedList.length) {
      if (categorizedList[i].items.length === 0) {
        categorizedList.splice(i, 1);
      }
      else {
        i++;
      }
    }

    return categorizedList;
  }

}
