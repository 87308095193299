import { Moment } from 'moment';

export const enum ClaimStatus {
    PENDING = 'PENDING',
    SCHEDULED = 'SCHEDULED',
    COMPLETED = 'COMPLETED',
    OFFLINE = 'OFFLINE'
}

export class ClaimHistoryScheduledModel {
    id: number;
    referenceNo: string;
    appointment: Moment;
    claimantId: string;
    companyId: string;
    agentId: string;
    startCall?: Moment;
    endCall?: Moment;
    latitude: number;
    longitude: number;
    status: ClaimStatus;
    callRating:number;
    deviceInfo:string;
    preRecordedVideo:string;
    agentName:string;
}