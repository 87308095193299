export const RegularExpressionConstant = {
    password: "^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).*$",
    alphanumeric: "^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$",
    alphabet: "^(?=.*[a-zA-Z])([a-zA-Z0-9]+)$",
    number: "\\d*"
}

export const RestrictInputRegularExpressionConstant = {
    alphanumeric: "[a-zA-Z0-9]",
    number: "\\d"
}