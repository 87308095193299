import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, forwardRef, Inject } from '@angular/core';
import { SessionService } from '../common/session.service';

export class ErrorsHandlerService implements ErrorHandler {

  constructor(
    @Inject(forwardRef(() => SessionService)) private sessionService: SessionService
  ) { }

  handleError(error: Error) {
    let session = this.sessionService;
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      session.appendLog('[Server or Connection Error]');
      if (!navigator.onLine) {
        // Handle offline error
        session.appendLog('[HTTP offline]');
      } else {
        // Handle Http Error (error.status === 403, 404...)
        session.appendLog('[HTTP Error]');
        session.appendLog('[HTTP Status] ' + error.status);
        session.appendLog('[HTTP Message] ' + error.message);
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)  
      session.appendLog('[Client Error]');
      session.appendLog('[Error Message] ' + error.message);
      session.appendLog('[Error Stack] ' + error.stack);
    }

    for (let log of session.logArr) {
      console.log(log);
    }
    // Send to server - pending for API ready
  }

}
