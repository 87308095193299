export class RestUrl {
    /* vidsight URL */
    public static API_URL = "/app/api/vidsight";
    public static AGENT_PROFILE = "/vidsight-agent-profiles";
    public static CHAT_HISTORY = "/vidsight-chat-histories";
    public static CHAT_HISTORY_PHOTO = "/vidsight-chat-histories-photo";
    public static CHAT_HISTORY_UNREAD = "/vidsight-chat-histories-isunread";
    public static CLAIM_HISTORY = "/vidsight-claim-histories";
    public static CLAIM_PHOTO = "/vidsight-claim-photos";
    public static CLAIM_MULTIPLE_PHOTO = "/vidsight-claim-photos-in-bulk";
    public static CLAIM_VIDEO = "/vidsight-claim-videos";
    public static LANGUAGE = "/vidsight-languages";
    public static OTP = "/vidsight-otp";
    public static USER_PROFILE = "/vidsight-user-profiles";
    public static COUNTRY_CODE = "/app/api/country-codes";
    public static CALL_QUEUE = "/vidsight-call-queues";
}