/**
 * Registered route for application 
 */
export const HttpStatusConstant = {
    UNKNOWN: 0,
    OK: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    PERMANENT_REDIRECT: 308,
    INTERNAL_ERROR: 500,
    SERVICE_UNAVAILABLE: 503
};
