import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimals'
})
export class DecimalsPipe implements PipeTransform {
  transform(value: any, numberOfDigits: number = 2, decimalSeparator: string = '.'): string {
    if (value === undefined || value === null || value === '' || typeof value === 'number' && isNaN(value)) {
      return value;
    }

    let valueStr = value;

    if (typeof valueStr !== 'string') {
      valueStr = valueStr.toString();
    }

    let splitValueStr = valueStr.split(decimalSeparator);

    if (splitValueStr.length < 2) {
      let zeroStr = '';

      for (let i = 0; i < numberOfDigits; i++) {
        zeroStr += '0';
      }

      splitValueStr.push(zeroStr);
    } else {
      let decimalStr = splitValueStr[splitValueStr.length - 1];

      if (decimalStr.length < numberOfDigits) {
        while (decimalStr.length < numberOfDigits) {
          decimalStr += '0';
        }

        splitValueStr[splitValueStr.length - 1] = decimalStr;
      }
    }

    return splitValueStr.join(decimalSeparator);
  }

}
