import { HttpClient } from '@angular/common/http';
import { EventEmitter, forwardRef, Inject } from '@angular/core';

export class TranslateService {

  onLanguageChange: EventEmitter<any> = new EventEmitter<any>();
  data: any = {};
  langLibrary: any = {};

  constructor(
    @Inject(forwardRef(() => HttpClient)) private http: HttpClient,
  ) { }

  use(lang: string): Promise<{}> {
    window.localStorage.setItem('lang', lang);

    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang}.json`;

      if (this.langLibrary[lang]) {
        this.data = this.langLibrary[lang];
        this.onLanguageChange.emit();
        resolve(this.data);
      } else {
        this.http.get<{}>(langPath).subscribe((translation) => {
          this.langLibrary[lang] = Object.assign({}, translation || {});
          this.data = this.langLibrary[lang];
          this.onLanguageChange.emit();
          resolve(this.data);
        }, (error) => {
          this.data = {};
          resolve(this.data);
        });
      }
    });
  }

}
