// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   apiUrl: window.location.protocol + "//" + window.location.hostname + ":3000/api", // Point to local json-server
//   authorization: "Basic cmNjcF9icm93c2VyOkFKSkQ3WTNYNlkw",

//   /** Encryption purpose */
//   nvalue: "8A90B14B2F2D0BA1CEDC1C4E050291F70687BB5A8875D89D98EC3108AD767FD1AD1BADEECA6412212935293843B5D35C47CB0A582ACA886484C47E65A98C12F82F16DEE8CFB8EFFFE32C9B4193E2B0D92F55975E0AB60D13DD3A0A9A74B241DAB3D9A76B16AEFBCDC8D432C0D7AB99A42B7BE65671FA6580A881593F1577F39B",
//   evalue: "10001"
// };

export const environment = {
  production: false,
  apiUrl: "https://sit.truesight.fermiondigital.com", // Point to SIT
  authorization: "Basic aWJzX2Jyb3dzZXI6TVh4WnRmZmFHenc=",

  /** Encryption purpose */
  nvalue: "C5065BC475B1657301FB7F0AA35AA9C4DC7E699D43D1B9CA4330619AEA723D4E01FBF55778BA4C585B6858230DB30EDE8D4B0AB28C3B0D3AA674F6C8AE38C596378A56947F2D0A3DF3889BBA8D85B8E2D86AAAEB360298BB4AA831CF0C3783C959DF2D7C648FD2E0A52AA26C1C6C76EC76BE1D5F1F4A895B92BD6D71A2B3ED55EBCE702C7465CC9ACB41E7B0B818892987F871527D6061DFE5588B2F82C5689754858A3DCD7571F8754D12B82C1E71D0677A7F620032545A1523741B18F56A193A29F64371E48891AA031639CE4B7ACE9FFDE04A2AEE31D5EEC0D210A1F6AB61D6D9BFC669F9ACC7827E17AE01A831638ED3B3270418E31BD2815E2B4D5BFBFD",
  evalue: "10001",

  /** EasyRTC Socket IO url */
  socketUrl: "https://vs-easyrtc.fermiondigital.com",
  socketPort: "9092"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
