import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChatHistoryModel } from 'src/app/model/history/chat-history-model';
import { ClaimHistoryModel } from 'src/app/model/history/claim-history-model';
import { ClaimPhotoModel } from 'src/app/model/history/claim-photo-model';
import { LanguageModel } from 'src/app/model/history/language-model';
import { UserProfileModel } from 'src/app/model/profile/user-profile-model';
import { environment } from '../../../environments/environment';
import { BaseService } from '../base.service';
import { RestUrl } from '../constant/rest-url';
import { CountryCodeModel } from 'src/app/model/common/country-code-model';
import { ClaimHistoryScheduledModel } from 'src/app/model/claim-history/claim-history-scheduled-model'
import { OtpModel } from 'src/app/model/profile/otp-model';
import { AgentProfileModel } from 'src/app/model/profile/agent-profile-model';
import { HttpParams } from '@angular/common/http';
declare const navigator: any;

const endpoint = environment.apiUrl;
const apiUrl = `${endpoint}${RestUrl.API_URL}`;

@Injectable({
  providedIn: "root"
})
export class RestService {

  url: string;

  constructor(
    private baseService: BaseService
  ) { }

  /** User Profile Rest*/
  getUserProfiles(): Observable<any> {
    this.url = `${apiUrl}${RestUrl.USER_PROFILE}`;
    return this.baseService.get(this.url);
  }

  getUserProfileByDevice(userProfile: UserProfileModel): Observable<UserProfileModel> {
    this.url = `${apiUrl}${RestUrl.USER_PROFILE}/device`;
    return this.baseService.post(this.url, userProfile);
  }

  loginUserProfile(userProfile: UserProfileModel): Observable<UserProfileModel> {
    this.url = `${apiUrl}${RestUrl.USER_PROFILE}/login`;
    return this.baseService.post(this.url, userProfile);
  }

  createUserProfile(userProfile: UserProfileModel): Observable<UserProfileModel> {
    this.url = `${apiUrl}${RestUrl.USER_PROFILE}`;
    return this.baseService.post(this.url, userProfile);
  }

  updateUserProfile(userProfile: UserProfileModel): Observable<UserProfileModel> {
    this.url = `${apiUrl}${RestUrl.USER_PROFILE}/device`;
    return this.baseService.put(this.url, userProfile);
  }

  /** Send SMS OTP Rest*/
  sendOtp(otp: OtpModel): Observable<void> {
    this.url = `${apiUrl}${RestUrl.OTP}/send`;
    return this.baseService.post(this.url, otp);
  }

  verifyOtp(otp: OtpModel): Observable<boolean> {
    this.url = `${apiUrl}${RestUrl.OTP}/verify`;
    return this.baseService.post(this.url, otp);
  }

  /** Claim History Rest*/
  createClaimHistoryClosure(claimHistory: ClaimHistoryModel): Observable<ClaimHistoryModel> {
    this.url = `${apiUrl}${RestUrl.CLAIM_HISTORY}/closure`;
    return this.baseService.put(this.url, claimHistory);
  }

  createClaimHistoryRating(claimHistory: ClaimHistoryModel): Observable<ClaimHistoryModel> {
    this.url = `${apiUrl}${RestUrl.CLAIM_HISTORY}/rating`;
    return this.baseService.put(this.url, claimHistory);
  }

  /* scheduled claim history */
  createClaimHistory(claimHistory: ClaimHistoryScheduledModel): Observable<ClaimHistoryScheduledModel> {
    this.url = `${apiUrl}${RestUrl.CLAIM_HISTORY}`;
    return this.baseService.post(this.url, claimHistory);
  }

  getClaimHistoryCount(mobileNo: string): Observable<ClaimHistoryScheduledModel> {
    let params = new HttpParams().set('claimantId.equals', mobileNo);
    this.url = `${apiUrl}${RestUrl.CLAIM_HISTORY}/count`;
    return this.baseService.get(this.url, params);
  }

  getClaimHistory(mobileNo: string, totalCount: string): Observable<ClaimHistoryScheduledModel> {
    let params = new HttpParams().set('claimantId.equals', mobileNo)
      .set('size', totalCount)
      .set('sort', "appointment,asc");
    this.url = `${apiUrl}${RestUrl.CLAIM_HISTORY}`;
    return this.baseService.get(this.url, params);
  }

  getClaimHistoryDesc(mobileNo: string, totalCount: string): Observable<ClaimHistoryScheduledModel> {
    let params = new HttpParams().set('claimantId.equals', mobileNo)
      .set('size', totalCount)
      .set('sort', "appointment,desc");
    this.url = `${apiUrl}${RestUrl.CLAIM_HISTORY}`;
    return this.baseService.get(this.url, params);
  }

  /** Claim Photo Rest*/
  createClaimPhoto(claimPhoto: ClaimPhotoModel): Observable<ClaimPhotoModel> {
    this.url = `${apiUrl}${RestUrl.CLAIM_PHOTO}`;
    return this.baseService.post(this.url, claimPhoto);
  }

  createMultipleClaimPhoto(claimPhoto: ClaimPhotoModel[]): Observable<ClaimPhotoModel> {
    this.url = `${apiUrl}${RestUrl.CLAIM_MULTIPLE_PHOTO}`;
    return this.baseService.post(this.url, claimPhoto);
  }

  getClaimPhoto(claimReferenceNo: string, totalCount: string): Observable<ClaimPhotoModel> {
    let params = new HttpParams().set('claimReferenceNo.equals', claimReferenceNo)
      .set('size', totalCount);
    this.url = `${apiUrl}${RestUrl.CLAIM_PHOTO}`;
    return this.baseService.get(this.url, params);
  }

  getClaimPhotoCount(mobileNo: string): Observable<ClaimPhotoModel> {
    let params = new HttpParams().set('claimantId.equals', mobileNo);
    this.url = `${apiUrl}${RestUrl.CLAIM_PHOTO}/count`;
    return this.baseService.get(this.url, params);
  }

  /** Claim Video Rest */
  createClaimVideo(refNo: string, dataStream: number): Observable<ClaimPhotoModel> {
    this.url = `${apiUrl}${RestUrl.CLAIM_VIDEO}/video/${refNo}`;
    return this.baseService.post(this.url, dataStream);
  }

  /** Chat History Rest*/
  createChatHistory(chatHistory: ChatHistoryModel): Observable<ChatHistoryModel> {
    this.url = `${apiUrl}${RestUrl.CHAT_HISTORY}`;
    return this.baseService.post(this.url, chatHistory);
  }

  createChatHistoryPhoto(chatHistory: ChatHistoryModel[]): Observable<ChatHistoryModel> {
    this.url = `${apiUrl}${RestUrl.CHAT_HISTORY_PHOTO}`;
    return this.baseService.post(this.url, chatHistory);
  }

  getUnreadChatCount(claimRefNo: string): Observable<ChatHistoryModel> {
    let params = new HttpParams()
      .set('isUnread.equals', "true")
      .set('claimReferenceNo.equals', claimRefNo)
      .set('isAgent.equals', "true");
    this.url = `${apiUrl}${RestUrl.CHAT_HISTORY}/count`;
    return this.baseService.get(this.url, params);
  }

  getTotalUnreadChatCount(claimRefNo: string[]): Observable<ChatHistoryModel> {
    let params = new HttpParams();
    params = params.set('isUnread.equals', "true")
      .set('isAgent.equals', "true");
    for (let i = 0; i < claimRefNo.length; i++) {
      params = params.append('claimReferenceNo.in', claimRefNo[i]);
    }
    this.url = `${apiUrl}${RestUrl.CHAT_HISTORY}/count`;
    return this.baseService.get(this.url, params);
  }

  getTotalChatCountByReferenceNo(claimReferenceNo: string): Observable<ChatHistoryModel> {
    let params = new HttpParams().set('claimReferenceNo.equals', claimReferenceNo);
    this.url = `${apiUrl}${RestUrl.CHAT_HISTORY}/count`;
    return this.baseService.get(this.url, params);
  }

  getChatHistory(claimReferenceNo: string, totalCount: string): Observable<ChatHistoryModel> {
    let params = new HttpParams()
      .set('claimReferenceNo.equals', claimReferenceNo)
      .set('size', totalCount);
    this.url = `${apiUrl}${RestUrl.CHAT_HISTORY}`;
    return this.baseService.get(this.url, params);
  }

  updateStatus(chatHistory: ChatHistoryModel[]): Observable<ChatHistoryModel> {
    this.url = `${apiUrl}${RestUrl.CHAT_HISTORY_UNREAD}`;
    return this.baseService.put(this.url, chatHistory);
  }

  checkInternetConnection(successCallback: any): void {
    if (navigator.connection.type == "none") {
      this.baseService.showOfflineErrorPopup();
    } else {
      successCallback();
    }
  }
  /** Country Code Rest*/
  getCountryCode(): Observable<CountryCodeModel> {
    this.url = `${endpoint}${RestUrl.COUNTRY_CODE}`;
    return this.baseService.get(this.url);
  }

  /** Language Rest*/
  getLanguageByCountryCode(countryCode: string): Observable<LanguageModel> {
    this.url = `${apiUrl}${RestUrl.LANGUAGE}/country/${countryCode}`;
    return this.baseService.get(this.url);
  }

  /** Agent Profile Rest*/
  getAgentProfile(id: number): Observable<AgentProfileModel> {
    this.url = `${apiUrl}${RestUrl.AGENT_PROFILE}/${id}`;
    return this.baseService.get(this.url);
  }

  updateAgentProfile(agentProfile: AgentProfileModel): Observable<AgentProfileModel> {
    this.url = `${apiUrl}${RestUrl.AGENT_PROFILE}`;
    return this.baseService.put(this.url, agentProfile);
  }

  deleteCallQueueProfile(mobileNumber: string): Observable<any> {
    let params = new HttpParams().set('claimantMobile.equals', mobileNumber);
    this.url = `${apiUrl}${RestUrl.CALL_QUEUE}`;
    return this.baseService.delete(this.url, params);
  }
}
