import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RouteConstant } from './constant/route-constant';
import { SharedService } from './service/common/shared.service';
import { CordovaService } from './service/common/cordova.service';
import { SessionService } from './service/common/session.service';
import { RestService } from 'src/app/service/rest/rest.service';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { VideoCallService } from './service/common/video-call.service';
import { AppConstant } from './constant/app-constant';
declare var cordova: any;
declare var window: any;
declare var navigator: any;

export let browserRefresh = false;

@Component({
  selector: 'vidsight-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  title = 'app';
  subscription: Subscription;

  constructor(
    private restService: RestService,
    private sharedService: SharedService,
    private cordovaService: CordovaService,
    private sessionService: SessionService,
    private videoCallService: VideoCallService,
    private router: Router
  ) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }


  ngOnInit() {
    let urlTokens = window.location.href.split("/");
    let urlKey = urlTokens[urlTokens.length - 1];
    if (urlKey != null && (urlKey.indexOf("agent") > -1 || urlKey == "")) {
      // manual handling for agent url
      let urlHash = urlKey.split("#");
      if (urlHash.length === 2) {
        this.sessionService.agentRoomNumber = urlHash[1];
      }
      this.sharedService.navigate(RouteConstant.AGENT);
    } else {
      document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
      this.sharedService.navigate(RouteConstant.PUBLIC);
    }
    this.registerWithFCM();
    this.getMessageFromGCM();
  }

  onDeviceReady() {
    this.sessionService.deviceId = window.device.uuid;

    document.addEventListener("backbutton", this.backButtonHandler.bind(this), false);

    window.Fingerprint.isAvailable((result: any) => {
      console.log("isAvailable:", result);
      this.sessionService.fingerprint.isAvailable = true;
      this.sessionService.fingerprint.isReady = true;
      this.sessionService.fingerprint.hasFace = (result === "face");
    }, (err: any) => {
      console.log(err);
      if (err.includes("not ready")) {
        this.sessionService.fingerprint.isAvailable = true;
        this.sessionService.fingerprint.isReady = false;
      } else {
        window.SamsungPass.checkSamsungPassSupport(() => {
          console.log("Samsung Pass Supported");
          this.sessionService.fingerprint.isAvailable = true;
          window.SamsungPass.checkForRegisteredFingers(() => {
            console.log("Check Samsung Pass Registered Fingers Success");
            this.sessionService.fingerprint.isReady = true;
            this.sessionService.fingerprint.isSamsungPass = true;
          }, () => {
            console.log("Check Samsung Pass Registered Fingers Failed");
          });
        }, () => {
          console.log("Samsung Pass Not Supported");
        });
      }
    });

    this.cordovaService.cordova = cordova;

    // push notification sourcecodes
    // let appPush = window.PushNotification.init({
    //   android: {},
    //   browser: {
    //     pushServiceURL: 'http://push.api.phonegap.com/v1/push'
    //   },
    //   ios: {
    //     alert: 'true',
    //     badge: true,
    //     sound: 'false'
    //   },
    //   windows: {}
    // });

    // appPush.on('registration', (data) => {
    //   console.log("DEVICE TOKEN REGISTRATION SUCCESS");
    //   console.log("PUSH REG ID : " + data.registrationId);
    // });

    // appPush.on('error', function (error) {
    //   console.log("APPPUSH ERROR: " + error.message);
    // });

    // appPush.on('notification', (data) => {
    //   console.log("NOTIFICATION RECEIVED");
    //   console.log(data);
    // });
  }

  registerWithFCM() {
    try {
      let that = this;
      if ((<any>window).FirebasePlugin == null) {
        // alert("FCMPlugin is null");
        return;
      }
      (<any>window).FirebasePlugin.onTokenRefresh(function (refreshToken) {
        console.log('onTokenRefresh method token >> ', refreshToken);
        if (window.localStorage.getItem(AppConstant.DEVICE_TOKEN) !== null) {
          let item = window.localStorage.getItem(AppConstant.DEVICE_TOKEN);
          console.log('Item of localstorage onTokenRefresh method', item);
          if (item !== refreshToken) {
            let mobileNo = window.localStorage.getItem(AppConstant.USER_ID);
            console.log('The phone number >> ', mobileNo);
            that.sessionService.userProfile.deviceToken = refreshToken;
            //Update user device token in backend and also update it in the localstorage
            that.restService.updateUserProfile(that.sessionService.userProfile).subscribe(() => {
              window.localStorage.setItem(AppConstant.DEVICE_TOKEN, that.sessionService.userProfile.deviceToken);
            });
          }
        }
      }, function (error) {
        console.error(error);
      });

    } catch (e) {
      console.error(e);
    }
  }

  getMessageFromGCM() {
    try {
      let that = this;
      (<any>window).FirebasePlugin.onMessageReceived(function (data) {
        console.log("Message type: " + data.messageType);
        if (data.messageType === "notification") {
          console.log("Notification message received");
          if (data.tap) {
            console.log(`***Tapped in [${data.tap}]***`);
            console.log(data);
            console.log('IF tapped in background, dont have title:', data.title);
            console.log('IF tapped in background, dont have body:', data.body);
            console.log('Flag: ', data.isBtnTrigger);
            console.log('Navigating to SECURE_VIDEO_CALL route =>');
            that.videoCallService.isBtnTrigger = data.isBtnTrigger;
            that.sharedService.navigate(RouteConstant.SECURE_VIDEO_CALL);
          }
        }
      }, function (error) {
        console.error(error);
      });
    } catch (error) {
      console.error(error);
    }
  }

  private backButtonHandler() {
    let loc = window.location.href.split("/");
    let channel = loc[loc.length - 2];
    let route = loc[loc.length - 1];
    if (channel === "public" && route === "landing") {
      if (confirm("Confirm exit app?")) {
        navigator.app.exitApp();
      }
    } else if (this.isPromptLogout(channel, route)) {
      this.sessionService.promptLogout(() => { this.sharedService.navigate(RouteConstant.PUBLIC_LANDING); });
    } else if (route === "claimant-view") {
      this.videoCallService.hangup.emit(true);
    } else {
      window.history.back();
    }
  }

  private isPromptLogout(channel: string, route: string): boolean {
    if ((channel === "public" && route === "verify")
      || (this.sessionService.isOnlyOtp && channel === "public" && route === "otp-verify")
      || (channel === "secure" && route === "dashboard")) {
      return true;
    }
    return false;
  }
}
