import { DatePipe } from '@angular/common';
import { forwardRef, Inject } from '@angular/core';
import * as Fingerprint2 from 'fingerprintjs2';
import { Promise } from 'q';
import { SessionName } from '../constant/session-name.enum';
import { UserProfileModel } from 'src/app/model/profile/user-profile-model';
import { AppConstant } from 'src/app/constant/app-constant';
import { ClaimHistoryScheduledModel, ClaimStatus } from 'src/app/model/claim-history/claim-history-scheduled-model';
import { Moment } from 'moment';
import { ChatHistoryModel } from 'src/app/model/history/chat-history-model';
import { IcuType } from '@angular/core/src/render3/interfaces/i18n';
declare const window: any;
/**
 * Session service class mainly to manage session storage of application
 */
export class SessionService {
  hashKey: string;
  deviceId: string;
  logArr: Array<string> = new Array<string>();
  fingerprint: any = {
    isAvailable: false,
    isReady: false,
    hasFace: false,
    isSamsungPass: false
  }
  isOtpForLogin: boolean = false;
  isOnlyOtp: boolean = false;
  userProfile: UserProfileModel = new UserProfileModel();
  agentRoomNumber: string;

  claimHistory: ClaimHistoryScheduledModel = new ClaimHistoryScheduledModel();
  chatHistory: ChatHistoryModel = new ChatHistoryModel();
  constructor(
    @Inject(forwardRef(() => DatePipe)) private datePipe: DatePipe) { }

  /**
   * Allow only session name registered in ```SessionName enum``` to place in session storage 
   * 
   * @param sessionName SessionName
   * @param sessionValue string
   */
  public setSessionStorage(sessionName: SessionName, sessionValue: string) {
    sessionStorage.setItem(sessionName, sessionValue);
  }

  /**
   * Return string based on ```SessionName enum``` provided 
   * @param sessionName SessionName
   */
  public getSessionStorage(sessionName: SessionName): string {
    return sessionStorage.getItem(sessionName);
  }

  /**
   * Get unique device id
   */
  public getDeviceId() {
    return this.deviceId;
  }

  /**
   * Set unique device id
   * @param deviceId 
   */
  public setDeviceId(deviceId: string) {
    this.deviceId = deviceId;
  }

  /**
   * Get secret hash key in plain text format
   */
  public getHashKey() {
    return this.hashKey;
  }

  /**
   * Set secret hash key in plain text format
   * @param hashKey 
   */
  public setHashKey(hashKey: string) {
    this.hashKey = hashKey;
  }

  public initUserProfile(data: any): void {
    this.userProfile.mobileNo = data.mobileNo;
    this.userProfile.name = data.name;
    this.userProfile.email = data.email;
    this.userProfile.profilePicture = data.profilePicture;
    this.userProfile.deviceId = data.deviceId;
    this.userProfile.isFace = data.isFace;
    this.userProfile.isFingerprint = data.isFingerprint;
    this.userProfile.lastLoginOn = data.lastLoginOn;
  }

  public loginUser(data: any): void {
    this.initUserProfile(data);
    window.localStorage.setItem(AppConstant.USER_ID, data.mobileNo);
    window.localStorage.setItem(AppConstant.ISFACEAUTH, data.isFace ? "1" : "0");
    window.localStorage.setItem(AppConstant.ISFINGERPRINTAUTH, data.isFingerprint ? "1" : "0");
  }

  public clearUserProfile(): void {
    this.userProfile = new UserProfileModel;
  }

  public logoutUser(): void {
    window.localStorage.removeItem(AppConstant.COUNTRY_CODE);
    window.localStorage.removeItem(AppConstant.USER_ID);
    window.localStorage.removeItem(AppConstant.ISFACEAUTH);
    window.localStorage.removeItem(AppConstant.ISFINGERPRINTAUTH);
    window.localStorage.removeItem(AppConstant.DEVICE_TOKEN);
    (<any>window).FirebasePlugin.unregister();
    this.clearUserProfile();
  }

  public promptLogout(successRoute: any): boolean {
    if (confirm("This will perform logout, are you sure?")) {
      this.logoutUser();
      if (successRoute) { successRoute(); }
      return true;
    } else {
      return false;;
    }
  }

  public scanFingerprint(scb: any, fcb: any): void {
    if (window.SamsungPass || window.Fingerprint) {
      if (this.fingerprint.isSamsungPass) {
        window.SamsungPass.startIdentifyingWithDialog(scb, fcb);
      } else {
        window.Fingerprint.show({
          clientId: "Fingerprint-Demo",
          clientSecret: "password"
        }, scb, fcb);
      }
    }
  }

  /**
   * Generate browser unique finger print, applicable for web and mobile browser
   */
  public generateFingerprint() {
    return Promise(function (resolve, reject) {
      new Fingerprint2().get(function (result, components) {
        let deviceId = (<any>window).uniqueDeviceId;
        if (!deviceId) {
          deviceId = result + new Date().getTime().toString().substr(6);
        }
        resolve(deviceId);
      });
    });
  }

  /**
   * Append error message
   * @param errorMessage 
   */
  public appendLog(errorMessage: string) {
    let date = new Date();
    this.logArr.push('\n' +
      this.datePipe.transform(date, "yyyy-MM-dd hh:mm:ss")
      + '-' + errorMessage);
  }

  public initHistoryDetails(referenceNo: string, appointment: Moment, claimantId: string, companyId: string, agentId: string, startCall: Moment, endCall: Moment, latitude: number, longitude: number, status: ClaimStatus, callRating: number, deviceInfo: string, preRecordedVideo: string, agentName: string): void {
    this.claimHistory.referenceNo = referenceNo;
    this.claimHistory.appointment = appointment;
    this.claimHistory.agentId = agentId;
    this.claimHistory.agentName = agentName;
    this.claimHistory.callRating = callRating;
    this.claimHistory.claimantId = claimantId;
    this.claimHistory.deviceInfo = deviceInfo;
    this.claimHistory.endCall = endCall;
    this.claimHistory.latitude = latitude;
    this.claimHistory.longitude = longitude;
    this.claimHistory.preRecordedVideo = preRecordedVideo;
    this.claimHistory.startCall = startCall;
    this.claimHistory.status = status;
  }
}
