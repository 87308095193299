export const RouteConstant = {

    /*-----------------------------------------------------------------------------
        AGENT PAGES
    -----------------------------------------------------------------------------*/

    AGENT: "/agent",

    /*-----------------------------------------------------------------------------
        PUBLIC PAGES
    -----------------------------------------------------------------------------*/

    PUBLIC: "/public",
    PUBLIC_LANDING: "/public/landing",
    PUBLIC_LOGIN: "/public/login",
    PUBLIC_LOGOUT: "/public/logout",
    PUBLIC_VERIFY: "/public/verify",
    PUBLIC_OTP_VERIFY: "/public/otp-verify",
    PUBLIC_WELCOME: "/public/welcome",
    PUBLIC_ALLOW_AUTH: "/public/allow-auth",

    /*-----------------------------------------------------------------------------
        SECURE PAGES
    -----------------------------------------------------------------------------*/

    SECURE: "/secure",

    /* Dashboard */
    SECURE_DASHBOARD: "/secure/dashboard",

    /* Profile */
    SECURE_PROFILE: "/secure/profile",

    /* Faq */
    SECURE_FAQ: "/secure/faq",

    /* Biometric Authentication */
    SECURE_BIOMETRIC: "/secure/biometric",

    /* Claim History */
    SECURE_HISTORY: "/secure/history",
    SECURE_HISTORY_DETAILS: "/secure/history/history-detail",

    /* Scheduled Claim */
    SECURE_SCHEDULED: "/secure/scheduled",
    SECURE_SCHEDULED_DETAILS: "/secure/scheduled/scheduled-detail",

    /* Chat History */
    SECURE_CHAT_HISTORY: "/secure/history/chat-history",

    /* Video Call */
    SECURE_VIDEO_CALL: "/secure/video-call",
    SECURE_VIDEO_CALL_CLAIM_TYPE: "/secure/video-call/claim-type",
    SECURE_VIDEO_CALL_CLAIMANT: "/secure/video-call/claimant-view",
    SECURE_VIDEO_CALL_RECORD_VIDEO: "/secure/video-call/record-video",
    SECURE_VIDEO_CALL_SCHEDULED_DATE: "/secure/video-call/scheduled-date",

    /* PHOTO */
    SECURE_PHOTO: "/secure/history/photo-list"
};
