import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[toggler]',
  exportAs: 'toggler'
})
export class TogglerDirective {

  @Input() untoggleOnOutsideClick: boolean = true;

  active: boolean = false;

  constructor(
    private el: ElementRef
  ) { }

  @HostListener('click') onClick(): void {
    this.active = !this.active;
  }

  @HostListener('document:click', ['$event']) onHostClick(event: MouseEvent): void {
    if (!this.untoggleOnOutsideClick || !event.target) {
      return;
    }

    if (!this.el.nativeElement.contains(event.target)) {
      this.active = false;
    }
  }
}
