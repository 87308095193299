import { Component } from '@angular/core';
import { CommonModalFunctions } from '../common-modal-functions';
import { CommonModalService } from '../common-modal-service';

@Component({
  selector: 'vidsight-confirm-content',
  templateUrl: './confirm-content.component.html',
  styleUrls: ['./confirm-content.component.scss']
})
export class ConfirmContentComponent extends CommonModalFunctions {

  constructor(
    commonModalService: CommonModalService
  ) {
    super(commonModalService);
  }

}
