import { Moment } from 'moment';

export const enum UserStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export class UserProfileModel {
    id: number;
    mobileNo: string;
    name: string;
    email: string;
    profilePicture: string;
    deviceId: string;
    isFace: boolean;
    isFingerprint: boolean;
    userStatus: UserStatus;
    createdOn: Moment;
    deactivatedOn: Moment;
    lastLoginOn: Moment;
    otp: string;
    imageContentType: string;
    image: any;
    deviceToken: string;
}