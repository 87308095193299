import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AgentComponent } from "./view/agent/agent.component";

const APP_ROUTES: Routes = [
  { path: "", redirectTo: "/agent", pathMatch: "full" },
  { path: "agent", component: AgentComponent },
  { path: "public", loadChildren: "./view/public/public.module#PublicModule" },
  { path: "secure", loadChildren: "./view/secure/secure.module#SecureModule" },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(APP_ROUTES)
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule { }
