import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CordovaService {

  cordova : any;

  constructor() { }
}
