import { EventEmitter, forwardRef, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { SERVICE_API } from '../../../environments/api-config';
import { RequestModel } from '../../model/base/request-body-model';
import { InitReqModel } from '../../model/init/init-req-model';
import { InitResModel } from '../../model/init/init-res-model';
import { BaseService } from '../base.service';

export class InitService {

  appHasInit: boolean;
  initEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  initResModel: InitResModel = new InitResModel();

  constructor(
    @Inject(forwardRef(() => BaseService)) private baseService: BaseService
  ) { }

  performInit(request: RequestModel<InitReqModel>): Observable<InitResModel> {
    return this.baseService.post(SERVICE_API.INIT, request);
  }

}
