export class InitResModel {
    locatorLastUpdatedOn: string;
    sessionTimeout: {
        global: number,
        prelogin: number,
        postlogin: number
    }
    localBankSetting: {
        version: string
    };
    trxSetting: {
        version: string
    };
    loginSecurityTips: Array<string>;
    login_type: string;
    passwordLength: PasswordLength;
}

export class PasswordLength {
    minLength: string;
    maxLength: string;
}
