/**
 * @class Easyrtc.
 *
 * @returns {Easyrtc} the new easyrtc instance.
 *
 * @constructs Easyrtc
 */
module.exports = function () {

	var self = this;
	/**
	 * Provides methods for building MediaStream recorders.
	 * @class Easyrtc_Recorder
	 */
	function trace(message, obj) {
		if (this.debugPrinter) {
			this.debugPrinter(message, obj);
		}
	}

	/**
	 * Determines if recording is supported by the browser.
	 * @function
	 * @memberOf Easyrtc_Recorder
	 * @returns true if recording is supported.
	 */
	this.supportsRecording = function () {
		return (typeof MediaRecorder !== "undefined" && navigator.getUserMedia);
	};

	/**
	 * Check if a particular codec can be used for recording.
	 * @function
	 * @memberOf Easyrtc_Recorder
	 * @param {String} codecName, either "vp8" or "vp9 or "h264"
	 * @returns true if the type can be used, or if the browser doesn't
	 *  support a method to find out.
	 */
	this.isRecordingTypeSupported = function (videoCodecName) {
		var mimeType = "video/webm;codecs=" + videoCodecName;
		if (MediaRecorder.isTypeSupported) {
			// chrome definitely, maybe firefox
			return MediaRecorder.isTypeSupported(mimeType);
		} else if (MediaRecorder.isMimeTypeSupported) {
			// maybe firefox
			return MediaRecorder.isMimeTypeSupported(mimeType);
		} else {
			if (typeof this.hasNoRecordTypeCheck === "undefined") {
				this.hasNoRecordTypeCheck = true;
				window.alert("This browser doesn't know what media types it supports. Assuming all types.");
			}
			return true;
		}
	};

	var mimeType;
	var audioBitRate;
	var videoBitRate;

	/**
	 * Set the desired codec for the video encoding.
	 * @function
	 * @memberOf Easyrtc_Recorder
	 * @param {String} codecName, either "vp8" or "vp9 or "h264"
	 * @returns true if the type can be used.
	 */
	this.setRecordingVideoCodec = function (videoCodecName) {
		if (!this.supportsRecording()) {
			return false;
		}
		if (this.isRecordingTypeSupported(videoCodecName)) {
			mimeType = "video/webm;codecs=" + videoCodecName;
			return true;
		} else {
			return false;
		}
	};

	/** Sets the target bit rate of the audio encoder.
	 * @param bitrate bits per second
	 */
	this.setRecordingAudioBitRate = function (bitRate) {
		audioBitRate = bitRate;
	};

	/** Sets the target bit rate of the video encoder.
	 * @param bitrate bits per second
	 */
	this.setRecordingVideoBitRate = function (bitRate) {
		videoBitRate = bitRate;
	};

	if (this.supportsRecording()) {
		this.setRecordingVideoCodec("mpeg4");
	}

	/**
	 * Create a recording object and attach a media stream to it.
	 * @function
	 * @memberOf Easyrtc_Recorder
	 * @param  {HTMLMediaStream} mediaStream
	 * @returns a recorder object or null if recording not supported.
	 */
	this.startRecording = function (mediaStream) {
		if (!this.supportsRecording()) {
			console.log("recording not supported by your browser");
			return null;
		}

		if (this.supportsRecording()) {
			this.setRecordingVideoCodec("vp8");
		}
		var recorderOptions = { mimeType: mimeType };

		if (audioBitRate) {
			recorderOptions.audioBitsPerSecond = audioBitRate;
		}

		if (videoBitRate) {
			recorderOptions.videoBitsPerSecond = videoBitRate;
		}
		var mediaRecorder = new MediaRecorder(mediaStream, recorderOptions);
		if (!mediaRecorder) {
			console.log("no media recorder");
			return;
		}
		mediaRecorder.start();
		mediaRecorder.onerror = function (e) {
			console.log("Media recording error:", e);
		};

		mediaRecorder.onwarning = function (e) {
			console.log("Media recording error:", e);
		};

		mediaRecorder.onstart = function (e) {
			console.log("Media recording started");
		};

		mediaRecorder.onstop = function (e) {
			console.log("Media recording stopped");
		};
		return mediaRecorder;
	}

	/** This method creates a media recorder and populates it's ondataavailable
	 * method so that your own callback gets called with the data.
	 * Use the media recorder's start(), stop(), pause() and resume() methods
	 * on the returned object.
	 * @function
	 * @memberOf Easyrtc_Recorder
	 * @param {HTMLMediaStream} mediaStream a local or remote media stream.
	 * @param {Function} dataCallback a function to receive the webm data from.
	 */
	this.recordToCallback = function (mediaStream, dataCallback) {
		var mediaRecorder = this.startRecording(mediaStream);
		if (!mediaRecorder) {
			return null;
		}
		mediaRecorder.ondataavailable = function (e) {
			dataCallback(e.data);
		};

		return mediaRecorder;
	};

	/** This method creates a media recorder that builds a blob
	 * Use the media recorder's start(), stop(), pause() and resume() methods
	 * on the returned object.
	 * @function
	 * @memberOf Easyrtc_Recorder
	 * @param  {HTMLMediaStream} mediaStream a local or remote media stream.
	 * @param {Function} blobCallback a callback function that gets called with a
	 *    blob once you invoke the stop method.
	 **/
	this.recordToBlob = function (mediaStream, blobCallback) {
		var chunks = [];

		function dataConsumer(chunk) {
			chunks.push(chunk);
		}

		var mediaRecorder = this.recordToCallback(mediaStream,
			dataConsumer);

		if (!mediaRecorder) {
			return null;
		}

		mediaRecorder.onstop = function () {
			blobCallback(new Blob(chunks, { type: "video/webm" }));
			chunks = [];
		};
		return mediaRecorder;
	};

	/** This method creates a media recorder that builds a file.
	 * Use the media recorder's start(), stop(), pause() and resume() methods
	 * on the returned object.
	 * @function
	 * @memberOf Easyrtc_Recorder
	 * @param {HTMLMediaStream} a local or remote media stream.
	 * @param {Object} downloadLink an anchor tag to attach the file to.
	 * @param {String} basename the name of the file. A .webm will be appended
	 *    to the file if its not already present. The file doesn't get written
	 *    until you call the mediaRecorder's stop method.
	 **/
	this.recordToFile = function (mediaStream, downloadLink, basename) {
		function blobCallback(blob) {
			var videoURL = window.URL.createObjectURL(blob);
			downloadLink.href = videoURL;
			downloadLink.appendChild(document.createTextNode(basename));

			var name = basename + ((basename.indexOf(".webm") > 0) ? "" : ".webm");
			downloadLink.setAttribute("download", name);
			downloadLink.setAttribute("name", name);
		}

		downloadLink.innerHTML = "";
		var mediaRecorder = this.recordToBlob(mediaStream, blobCallback);
		return mediaRecorder;
	};

	var endRecording = false;
	var endSelfRecording = false;
	var endCallerRecording = false;
	var endRecordingFunction;
	var onceTrigger = 1;
	this.enableEndRecording = function (endVideoRecordingCallBack) {
		endRecording = true;
		endRecordingFunction = endVideoRecordingCallBack;
	}

	function triggerCallBack() {
		endRecordingFunction();
	}

	var selfVideoUrls = [];
	var callerVideoUrls = [];
	var zipFileUrl;
	/** This method creates a media recorder that builds a file.
	 * Use the media recorder's start(), stop(), pause() and resume() methods
	 * on the returned object.
	 * @function
	 * @memberOf Easyrtc_Recorder
	 * @param {HTMLMediaStream} a local or remote media stream.
	 **/
	this.recordToZip = function (mediaStream, isSelf) {
		function blobCallback(blob) {
			getSeekableBlob(blob, function (seekableBlob) {
				videoBlobs.push(seekableBlob);
				var videoURL = window.URL.createObjectURL(seekableBlob);
				if (isSelf) {
					selfVideoUrls.push(videoURL);
					endSelfRecording = true;
				} else {
					callerVideoUrls.push(videoURL);
					endCallerRecording = true;
				}
				if (endRecording && endSelfRecording && endCallerRecording && onceTrigger >= 2) {
					triggerCallBack();
				} else if (endRecording && endCallerRecording && onceTrigger == 1) {
					triggerCallBack();
				}
			})
		}
		if (isSelf) {
			onceTrigger++;
			endSelfRecording = false;
		} else {
			endCallerRecording = false;
		}

		var mediaRecorder = this.recordToBlob(mediaStream, blobCallback);
		return mediaRecorder;
	};

	this.getZipFileUrl = function () {
		return zipFileUrl;
	}

	var videoBlobs = []
	this.preRecordToZip = function (mediaStream, callback) {
		function blobCallback(blob) {
			getSeekableBlob(blob, function (seekableBlob) {
				videoBlobs.push(seekableBlob);
				callback();
			})
		}
		var mediaRecorder = this.recordToBlob(mediaStream, blobCallback);
		return mediaRecorder;
	};

	function getSeekableBlob(inputBlob, callback) {
		var reader = new EBML.Reader();
		var decoder = new EBML.Decoder();
		var tools = EBML.tools;
		var fileReader = new FileReader();
		fileReader.onload = function (e) {
			var ebmlElms = decoder.decode(this.result);
			ebmlElms.forEach(function (element) {
				reader.read(element);
			});
			reader.stop();
			var refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
			var body = this.result.slice(reader.metadataSize);
			var newBlob = new Blob([refinedMetadataBuf, body], {
				type: 'video/webm'
			});
			callback(newBlob);
		};
		fileReader.readAsArrayBuffer(inputBlob);
	}

	this.getVideoBlobs = function () {
		return videoBlobs[0];
	};

	this.getZipFileWithUrls = function (zip, zipFileUtil, fileSaver, roomHash) {
		return new Promise((resolve, reject) => {
			var count = 0;
			callerVideoUrls.forEach(function (callerUrl, index) {
				var selfFileName = "Claimant_" + roomHash + "_" + (index + 1) + ".mp4";
				zipFileUtil.getBinaryContent(callerUrl, function (err, callerData) {
					if (err) {
						throw err;
					}
					count++;
					zip.file(selfFileName, callerData, { binary: true });
					if (count == callerVideoUrls.length) {
						count = 0;
						if (selfVideoUrls.length == 0) {
							zip.generateAsync({ type: 'blob' }, function updateCallback(metadata) {
								var msg = "progression : " + metadata.percent.toFixed(2) + " %";
								if (metadata.currentFile) {
									msg += ", current file = " + metadata.currentFile;
								}
								console.log("msg:", msg);
							}).then(function (content) {
								zipFileUrl = content;
								selfVideoUrls = [];
								callerVideoUrls = [];
								setTimeout(function () {
									resolve();
								}, 1500);
							});
						} else {
							selfVideoUrls.forEach(function (selfUrl, index) {
								var callerFileName = "Agent_" + roomHash + "_" + (index + 1) + ".mp4";
								zipFileUtil.getBinaryContent(selfUrl, function (err, selfData) {
									if (err) {
										throw err;
									}
									count++;
									zip.file(callerFileName, selfData, { binary: true });
									if (count == selfVideoUrls.length) {
										zip.generateAsync({ type: 'blob' }, function updateCallback(metadata) {
											var msg = "progression : " + metadata.percent.toFixed(2) + " %";
											if (metadata.currentFile) {
												msg += ", current file = " + metadata.currentFile;
											}
											console.log("msg:", msg);
										}).then(function (content) {
											zipFileUrl = content;
											selfVideoUrls = [];
											callerVideoUrls = [];
											setTimeout(function () {
												resolve();
											}, 1500);
										});
									}
								});
							});
						}
					}
				});
			});
		});
	};

	this.getVideoWithBlob = function (filename, blob, fileSaver, callback) {
		document.addEventListener("deviceready", function () {
			if (window.cordova && cordova.platformId !== "browser") {
				var storageLocation = "";
				switch (device.platform) {
					case "Android":
						storageLocation = cordova.file.externalRootDirectory + "download";
						break;

					case "iOS":
						storageLocation = cordova.file.documentsDirectory;
						break;
				}
				var folderPath = storageLocation;
				window.resolveLocalFileSystemURL(
					folderPath,
					function (dir) {
						dir.getFile(
							filename,
							{
								create: true
							},
							function (file) {
								file.createWriter(
									function (fileWriter) {
										fileWriter.write(blob);
										fileWriter.onwriteend = function () {
											videoBlobs = [];
											callback();
											// var url = file.toURL();
											// cordova.plugins.fileOpener2.open(url, mimeType, {
											// 	error: function error(err) {
											// 		console.error(err);
											// 	},
											// 	success: function success() {
											// 		console.log("success with opening the file");
											// 		videoBlobs = [];
											// 	}
											// });
										};
										fileWriter.onerror = function (err) {
											console.error(err);
										};
									},
									function (err) {
										console.error(err);
									}
								);
							},
							function (err) {
								console.error(err);
							}
						);
					},
					function (err) {
						console.error(err);
					}
				);
			} else {
				fileSaver.saveAs(blob, filename);
			}
		});
	};

	return self;
};