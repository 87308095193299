import { EventEmitter } from "@angular/core";

export class CommonModalService {

  showErrorPopup: EventEmitter<any> = new EventEmitter<any>();
  showConfirmPopup: EventEmitter<any> = new EventEmitter<any>();
  showLoader: EventEmitter<boolean> = new EventEmitter<boolean>();
  activateQrScanner: EventEmitter<any> = new EventEmitter<any>();
  deactivateQrScanner: EventEmitter<any> = new EventEmitter<any>();
  showCamera: EventEmitter<any> = new EventEmitter<any>();

  callback: any;

  public setCallback(callback: any): void {
    this.callback = callback;
  }

  public getCallback(button?: number): Function {
    if (this.callback && this.callback instanceof Function && button === 1) {
      return this.callback();
    } else {
      if (button === 1 && this.callback.primary) {
        return this.callback.primary();
      } else if (button === 2 && this.callback.secondary) {
        return this.callback.secondary();
      }
    }

    return;
  }

}
