import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgDatepickerModule } from 'ng2-datepicker';
import { AmountValidatorDirective } from '../../directive/amount-validator.directive';
import { EmailValidatorDirective } from '../../directive/email-validator.directive';
import { RestrictInputToDirective } from '../../directive/restrict-input-to.directive';
import { TogglerDirective } from '../../directive/toggler.directive';
import { DecimalsPipe } from '../../pipes/decimals.pipe';
import { SafeHtmlPipe } from '../../pipes/sanitizeHtml.pipe';
import { RegexPipe } from 'src/app/pipes/regex.pipe';
import { ThousandsSeparatorPipe } from '../../pipes/thousands-separator.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgDatepickerModule,
    NgSelectModule,
    RouterModule
  ],
  declarations: [
    // Directives
    AmountValidatorDirective,
    EmailValidatorDirective,
    RestrictInputToDirective,
    TogglerDirective,

    // Pipes
    DecimalsPipe,
    SafeHtmlPipe,
    RegexPipe,
    ThousandsSeparatorPipe,
    TranslatePipe

    // Components
  ],
  exports: [
    // Modules
    CommonModule,
    FormsModule,
    NgDatepickerModule,
    NgSelectModule,
    RouterModule,

    // Directives
    AmountValidatorDirective,
    EmailValidatorDirective,
    RestrictInputToDirective,
    TogglerDirective,

    // Pipes
    DecimalsPipe,
    SafeHtmlPipe,
    RegexPipe,
    ThousandsSeparatorPipe,
    TranslatePipe

    // Components
  ]
})
export class SharedModule { }
