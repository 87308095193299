import { Moment } from 'moment';

export class ChatHistoryModel {
        claimReferenceNo: string;
        content: string;
        isAgent: boolean;
        sentOn: Moment;
        isUnread: boolean;
        isImage: boolean;
        image:any;
        imageContentType: string;
}