import { environment } from "./environment";

export const SERVICE_API = {

    /*-----------------------------------------------------------------------------
        PUBLIC PAGES
    -----------------------------------------------------------------------------*/

    INIT: environment.apiUrl + "/v1/public/init",
    USER_INQUIRY: environment.apiUrl + "/v1/common/user/inquiry",
    USER_SELF: environment.apiUrl + "/v1/user/self",
    TERMSCONDITION: environment.apiUrl + "/v1/common/user/tnc",
    SECURE_WORD: environment.apiUrl + "/v1/public/secure-word",
    LOGIN: environment.apiUrl + "/v1/oauth/token",
    GENERATE_CAPTCHA: environment.apiUrl + "/v1/captcha/generate_captcha",
    LOGOUT: environment.apiUrl + "/v1/common/logout-summary",

    /* Registration */
    REGISTRATION_IDTYPE_GET: environment.apiUrl + "/v1/public/registration/idType",
    REGISTRATION_STATE_GET: environment.apiUrl + "/v1/public/registration/state",
    REGISTRATION_FTR_STEP_1: environment.apiUrl + "/v1/public/registration/ftr_step1",
    REGISTRATION_FTR_STEP_2: environment.apiUrl + "/v1/public/registration/ftr_step2",
    REGISTRATION_VAL_OTP: environment.apiUrl + "/v1/public/registration/requestOTAC",
    REGISTRATION_AUTH_POST: environment.apiUrl + "/v1/public/registration/ftr_auth",
    REGISTRATION_FORM_POST: environment.apiUrl + "/v1/public/registration/ftr_form",
    REGISTRATION_LIMIT_GET: environment.apiUrl + "/v1/public/registration/limit",

    /* Forgot details */
    FORGOT_DETAILS_REQUEST_TAC: environment.apiUrl + "/v1/public/forgotidpwrd/request-tac",
    FORGOT_DETAILS_VALIDATE_TAC: environment.apiUrl + "/v1/public/forgotidpwrd/validate-tac/s-forgotidpwd-ibs-acc",
    FORGOT_DETAILS_RESET_PWRD: environment.apiUrl + "/v1/public/forgotidpwrd/pwrdreset",
    FORGOT_DETAILS_VALIDATE: environment.apiUrl + "/v1/public/forgotidpwrd/form/s-forgotidpwd-ibs-acc",

    /*-----------------------------------------------------------------------------
        SECURE PAGES
    -----------------------------------------------------------------------------*/

    ACCOUNT_SUMMARY: environment.apiUrl + "/v1/enquiry/accounts/summary",
    ACCOUNT_DETAILS: environment.apiUrl + "/v1/enquiry/accounts",
    ACCOUNT_HISTORY: environment.apiUrl + "/v1/enquiry/history",
    EDIT_NICKNAME: environment.apiUrl + "/v1/enquiry/accountnickname/update",
    REQUEST_OTP: environment.apiUrl + "/v1/transaction/request-tac",

    /* Dashboard */
    DASHBOARD_BANNER: environment.apiUrl + "/v1/enquiry/dashboard/banner/list",
    DASHBOARD_ANNOUNCEMENT: environment.apiUrl + "/v1/enquiry/dashboard/announcement/list",
    DASHBOARD_HAJJSTATUS: environment.apiUrl + "/v1/enquiry/dashboard/hajj-status",

    /* Term Deposit */
    TERM_DEPOSIT_PLACEMENT_FORM: environment.apiUrl + "/v1/transaction/td-placement/form",
    TERM_DEPOSIT_PLACEMENT: environment.apiUrl + "/v1/transaction/td-placement",
    TERM_DEPOSIT_WITHDRAWAL_FORM: environment.apiUrl + "/v1/transaction/td-withdrawal/form",
    TERM_DEPOSIT_WITHDRAWAL: environment.apiUrl + "/v1/transaction/td-withdrawal",

    /* My Favourites */
    FAVOURITE_LIST: environment.apiUrl + "/v1/enquiry/favourite-maint/list",
    FAVOURITE_ADD_LIST: environment.apiUrl + "/v1/enquiry/favourite-maint/list-add",
    FAVOURITE_BILLER: environment.apiUrl + "/v1/enquiry/favourite-maint/biller",
    FAVOURITE_JOMPAY: environment.apiUrl + "/v1/enquiry/favourite-maint/biller",
    FAVOURITE_OTHER_BANK: environment.apiUrl + "/v1/enquiry/favourite-maint/other",
    FAVOURITE_INTRABANK: environment.apiUrl + "/v1/enquiry/favourite-maint/intrabank",
    FAVOURITE_FINANCING: environment.apiUrl + "/v1/enquiry/favourite-maint/financing",
    FAVOURITE_REMITTANCE: environment.apiUrl + "/v1/enquiry/favourite-maint/remittance",
    FAVOURITE_DETAILS: environment.apiUrl + "/v1/enquiry/favourite-maint",

    /* Cheque Management */
    CHEQUE_MANAGEMENT: environment.apiUrl + "/v1/transaction/chequemgmt",
    CHEQUE_MANAGEMENT_CHEQUE_INQUIRY: environment.apiUrl + "/v1/transaction/chequemgmt/cheque-inquiry",
    CHEQUE_MANAGEMENT_STOP_CHEQUE: environment.apiUrl + "/v1/transaction/chequemgmt/stop",
    CHEQUE_MANAGEMENT_BOOK_REQUEST: environment.apiUrl + "/v1/transaction/chequemgmt/request",

    /* E-statement */
    E_STATEMENT_LIST: environment.apiUrl + "/v1/enquiry/estatement/list",
    E_STATEMENT_SEARCH: environment.apiUrl + "/v1/enquiry/estatement/search",
    E_STATEMENT_DOWNLOAD: environment.apiUrl + "/v1/enquiry/estatement/download",
    E_STATEMENT_HISTORY: environment.apiUrl + "/v1/enquiry/estatement/history",

    /* Fund Transfer */
    TRANSFER_FUND_DBACC: environment.apiUrl + "/v1/transaction/fund-transfer/form/f-sendMoney",
    TRANSFER_FUND_CDACC: environment.apiUrl + "/v1/transaction/fund-transfer/credit/f-sendMoney",
    TRANSFER_FUND_DT: environment.apiUrl + "/v1/enquiry/down-time/payTransfer/f-sendMoney",
    TRANSFER_FUND: environment.apiUrl + "/v1/transaction/fund-transfer/f-sendMoney",

    /* Remittance */
    REMITTANCE_FOREX_RATE: environment.apiUrl + "/v1/transaction/remittance/ftt/forexRate?selectedCurrency=",
    REMITTANCE_BENE_BANK: environment.apiUrl + "/v1/transaction/remittance/ftt/bank",
    REMITTANCE_GET_DEBITING_ACCOUNTS: environment.apiUrl + "/v1/transaction/remittance/ftt/form",
    REMITTANCE_VALIDATION: environment.apiUrl + "/v1/transaction/remittance/ftt/validate",
    REMITTANCE_INSERT: environment.apiUrl + "/v1/transaction/remittance/ftt/process",

    /* Pay Bills */
    PAY_BILL_GET_DEBITING_ACCOUNTS: environment.apiUrl + "/v1/transaction/pay-bill/init/f-payBill",
    PAY_BILL_GET_BILLER_LIST: environment.apiUrl + "/v1/transaction/pay-bill/biller/f-payBill",
    PAY_BILL_SEARCH_BILL: environment.apiUrl + "/v1/transaction/pay-bill",
    PAY_BILL: environment.apiUrl + "/v1/transaction/pay-bill/form/f-payBill",

    /* JomPAY */
    JOMPAY_INIT: environment.apiUrl + "/v1/transaction/pay-bill/init",
    JOMPAY_FORM: environment.apiUrl + "/v1/transaction/pay-bill/form",
    JOMPAY_BILL: environment.apiUrl + "/v1/transaction/pay-bill/bill",
    JOMPAY_BILLER: environment.apiUrl + "/v1/transaction/pay-bill/biller",

    /* Pay Financing */
    PAY_FINANCING_DBACC: environment.apiUrl + "/v1/transaction/fund-transfer/form/f-payFinancing",
    PAY_FINANCING_CDACC: environment.apiUrl + "/v1/transaction/fund-transfer/credit/f-payFinancing",
    PAY_FINANCING_DT: environment.apiUrl + "/v1/enquiry/down-time/payTransfer/f-payFinancing",
    PAY_FINANCING: environment.apiUrl + "/v1/transaction/fund-transfer/f-payFinancing",

    /* My Profile */
    MY_PROFILE_UPDATE_PARTICULARS_FORM: environment.apiUrl + "/v1/transaction/user-contact/list",
    MY_PROFILE_UPDATE_PARTICULARS: environment.apiUrl + "/v1/transaction/user-contact/update",
    MY_PROFILE_UPDATE_SECURE_WORD: environment.apiUrl + "/v1/transaction/secure-word-setup",
    MY_PROFILE_CHANGE_PASSWORD_FORM: environment.apiUrl + "/v1/transaction/changepassword/form",
    MY_PROFILE_CHANGE_PASSWORD_UPDATE: environment.apiUrl + "/v1/transaction/changepassword/update",
    MY_PROFILE_TRANSACTION_LIMIT_FORM: environment.apiUrl + "/v1/transaction/user-trx-limit/form",
    MY_PROFILE_TRANSACTION_LIMIT_UPDATE: environment.apiUrl + "/v1/transaction/user-trx-limit/update",
    MY_PROFILE_TRANSACTION_HISTORY_FORM: environment.apiUrl + "/v1/enquiry/transaction/form/s-ibsTransactionHistory",
    MY_PROFILE_TRANSACTION_HISTORY_DETAILS: environment.apiUrl + "/v1/enquiry/transaction/detail",
    MY_PROFILE_TRANSACTION_HISTORY_SEARCH: environment.apiUrl + "/v1/enquiry/th-transaction-history/list",
    MY_PROFILE_PIN_CHANGE_CARD_LIST: environment.apiUrl + "/v1/transaction/cardservices/pin-maintenance/card-listing",
    MY_PROFILE_PIN_CHANGE_FORM: environment.apiUrl + "/v1/transaction/cardservices/pin-maintenance/form/f-pinMaintHistory",
    MY_PROFILE_PIN_CHANGE_INSERT: environment.apiUrl + "/v1/transaction/cardservices/pin-maintenance/insert/f-pinMaintHistory",
    MY_PROFILE_SCHEDULED_TRANSACTION_FORM: environment.apiUrl + "/v1/transaction/scheduled-trx/form/s-view-si",
    MY_PROFILE_SCHEDULED_TRANSACTION_SEARCH: environment.apiUrl + "/v1/transaction/scheduled-trx/search",
    MY_PROFILE_SCHEDULED_TRANSACTION_STOP_DETAILS: environment.apiUrl + "/v1/transaction/scheduled-trx/details",
    MY_PROFILE_SCHEDULED_TRANSACTION_STOP_SINGLE_TXN: environment.apiUrl + "/v1/transaction/scheduled-trx/stop",
    MY_PROFILE_SCHEDULED_TRANSACTION_STOP_ALL_TXN: environment.apiUrl + "/v1/transaction/scheduled-trx/stop-all",
    MY_PROFILE_DEBIT_CARD_PURCHASE_LIMIT: environment.apiUrl + "/v1/transaction/cardservices/debit-trx-limit",
    MY_PROFILE_DEBIT_CARD_PURCHASE_LIMIT_DETAILS: environment.apiUrl + "/v1/transaction/cardservices/debit-trx-limit/detail",
    MY_PROFILE_DEBIT_CARD_PURCHASE_LIMIT_UPDATE: environment.apiUrl + "/v1/transaction/cardservices/debit-trx-limit/update",
    MY_PROFILE_ATM_WITHDRAWAL_LIMIT: environment.apiUrl + "/v1/transaction/cardservices/atm-trx-limit",
    MY_PROFILE_ATM_WITHDRAWAL_LIMIT_DETAILS: environment.apiUrl + "/v1/transaction/cardservices/atm-trx-limit/detail",
    MY_PROFILE_ATM_WITHDRAWAL_LIMIT_UPDATE: environment.apiUrl + "/v1/transaction/cardservices/atm-trx-limit/update",

    /* Mailbox */
    MAILBOX: environment.apiUrl + "/v1/enquiry/mailbox",
    MAILBOX_COUNT: environment.apiUrl + "/v1/enquiry/mailbox/count",
    MAILBOX_CREATE_CONVERSATION: environment.apiUrl + "/v1/enquiry/mailbox/createConversationTrx",
    MAILBOX_REMOVE_CONVERSATION: environment.apiUrl + "/v1/enquiry/mailbox/removeConversationTrx",
    MAILBOX_UPDATE_READ_FLAG: environment.apiUrl + "/v1/enquiry/mailbox/updateReadFlagTrx",

    /* Kelab TaHa */
    KELAB_TAHA: environment.apiUrl + "/v1/transaction/kelabtaha",
    KELAB_TAHA_FORM: environment.apiUrl + "/v1/transaction/kelabtaha/form",
    KELAB_TAHA_CHECK_STATUS: environment.apiUrl + "/v1/enquiry/kelabtaha/status",
    KELAB_TAHA_MEMBERSHIP: environment.apiUrl + "/v1/enquiry/kelabtaha/member",
    KELAB_TAHA_DIRECTORY: environment.apiUrl + "/v1/enquiry/kelabtaha/directory",

    /* Settings */
    ACTIVATE_BIOMETRIC: "/v1/transaction/device/activate"
};
